import RecipeResponse from "./RecipeResponse";
import AbstractRecipe from "./AbstractRecipe";

class Recipe extends AbstractRecipe {

    static createFromRecipeResponse(recipeResponse: RecipeResponse): Recipe
    {
        const recipe: Recipe = new Recipe();

        if (recipeResponse.id === null) {
            throw new Error();
        }

        super.fillFromRecipeResponse(recipe, recipeResponse);

        return recipe;
    }
}

export default Recipe;