import React from 'react';

interface FooterProps extends React.PropsWithChildren {

}

const Footer = (props: FooterProps): React.JSX.Element => {
    return (
        <footer className="fixed-bottom">
            <nav className="bg-white shadow-lg">
                <div className="container-fluid">
                    {props.children}
                </div>
            </nav>
        </footer>
    );
}

export default Footer;
