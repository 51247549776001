import FontAwesomeIcon from "./FontAwesomeIcon";
import BootstrapIcon from "./BootstrapIcon";
import MaterialIcon from "./MaterialIcon";
import React from "react";

interface IconProps {
    readonly icon: string;
    readonly iconType: IconType;
    readonly className?: string;
}

type IconType = 'Bootstrap' | 'FontAwsome' | 'Material';

const Icon = (props: IconProps): React.JSX.Element => {
    if (props.iconType === 'FontAwsome') {
        return (
            <FontAwesomeIcon iconClassName={props.icon + ((props.className !== undefined) ? ' ' + props.className : '')} />
        );
    }

    if (props.iconType === 'Bootstrap') {
        return (
            <BootstrapIcon iconClassName={props.icon + ((props.className !== undefined) ? ' ' + props.className : '')} />
        );
    }

    if (props.iconType === 'Material') {
        return (
            <MaterialIcon iconClassName={props.icon} additionalClassName={((props.className !== undefined) ? ' ' + props.className : '')} />
        );
    }

    return (<></>);
}

export default Icon;