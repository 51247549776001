import React, {useEffect, useState} from "react";
import Recipe from "../../../Entity/Recipe/Recipe";
import ButtonWithIcon from "../../../../Component/ButtonWithIcon";
import {NavigateFunction, useNavigate} from "react-router-dom";
import PictureService from "../../../Image/PictureService";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import Authentication from "../../../Entity/Authentication/Authentication";
import PictureView from "../../Component/PictureView";
import Picture from "../../../Entity/Picture/Picture";
import AccountUser from "../../../Entity/AccountUser/AccountUser";
import AccountUserService from "../../../AccountUser/AccountUserService";
import RecipeReaction from "../../../Entity/Recipe/RecipeReaction";
import RecipeReactionService from "../../../Recipe/RecipeReactionService";
import RecipeReactionElement from "./RecipeReactionElement";

interface RecipeCardProps {
    readonly recipe: Recipe;
    readonly accountUser?: AccountUser;
    readonly accountUserService?: AccountUserService;
}

const RecipeCard = (props: RecipeCardProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [pictureView, setPictureView] = useState<React.JSX.Element|null|undefined>(undefined);
    const [recipeReactions, setRecipeReactions] = useState<RecipeReaction[]|undefined>(undefined);
    const navigate: NavigateFunction = useNavigate();
    const [accountUser, setAccountUser] = useState<AccountUser|undefined>(undefined);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();

    async function loadPicture(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const pictureService: PictureService = new PictureService(authentication);
        const pictures: Picture[] = await pictureService.getByRecipeId(props.recipe.id);

        if (pictures.length <= 0) {
            return;
        }

        const e = <PictureView picture={pictures[0]} className={"card-img card-img-category"} />;
        setPictureView(e);
    }

    async function loadRecipeReactions(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const recipeReactionService: RecipeReactionService = new RecipeReactionService(authentication);
        const reactions: RecipeReaction[] = await recipeReactionService.fetchByRecipeId(props.recipe.id);

        setRecipeReactions(reactions);
    }

    async function loadAccountUser(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const accountUserService: AccountUserService = new AccountUserService(authentication);
        setAccountUser(
            await accountUserService.profile()
        );
    }

    useEffect((): void => {
        if (pictureView !== undefined) {
            return;
        }

        loadPicture();

    }, [pictureView]);

    useEffect((): void => {
        if (recipeReactions !== undefined) {
            return;
        }

        loadRecipeReactions();

    }, [recipeReactions]);

    useEffect((): void => {
        if (accountUser !== undefined) {
            return;
        }

        loadAccountUser();

    }, []);

    const editRecipe = (): void => {
        navigate('/rezepte/editor/' + props.recipe.id);
    }

    const readRecipe = (): void => {
        navigate('./' + props.recipe.id);
    }

    return (
        <div className="board card-shadow card mx-sm-3 mx-1 p-1">
            <div className="card-header">
                {props.recipe.name}
            </div>
            <div className="card-img-top">
                {pictureView !== undefined && pictureView !== null &&
                    <>
                    {pictureView}
                    </>
                }
            </div>
            <div className="card-footer">
                <div className="row">
                    <div className="col">
                        {
                            (
                                props.accountUser !== undefined && props.accountUser.id === props.recipe.accountUserId
                                || props.accountUserService !== undefined && props.accountUser !== undefined && props.accountUserService.isGranted('ROLE_ADMINISTRATOR', props.accountUser.roles)
                            )
                            &&
                            <ButtonWithIcon icon="bi-pencil" additionalClassName={"fa-pull-right"} text="Bearbeiten" onClick={editRecipe} iconType="Bootstrap" />
                        }
                        <ButtonWithIcon icon="bi-eyeglasses" additionalClassName={"fa-pull-right"} text="Anschauen" onClick={readRecipe} iconType="Bootstrap" />
                    </div>
                </div>
                { recipeReactions !== undefined && accountUser !== undefined &&
                    <div className="row">
                        <div className="col pt-3">
                            <RecipeReactionElement recipeReactions={recipeReactions} accountUser={accountUser} recipe={props.recipe} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default RecipeCard;