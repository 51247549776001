import Alert from '../Domain/Entity/Alert/Alert';
import React, { useEffect, useState } from 'react';

interface AlertBoxProps extends React.PropsWithChildren {
    readonly alert: Alert;
    readonly autoDismiss: boolean;
    readonly dismissTimeout?: number;
    readonly onDismiss?: () => void;
}

const AlertBox = (props: AlertBoxProps): React.JSX.Element => {
    const [visible, setVisible] = useState<boolean>(true);

    const [dismissed, setDismissed] = useState<boolean>(false);

    const [alertClass, setAlertClass] = useState<string>();

    useEffect((): () => void => {
        let timeout: NodeJS.Timeout | number | undefined = undefined;

        if (props.autoDismiss === true && props.dismissTimeout !== undefined && visible === true && dismissed === false) {
            timeout = setTimeout((): void => {
                setVisible(false);

                if (props.onDismiss !== undefined) {
                    props.onDismiss();
                }

                setTimeout((): void => {
                    setDismissed(true);
                }, 500);
            }, props.dismissTimeout);
        }

        return (): void => {
            if (timeout !== undefined) {
                clearTimeout(timeout);
            }
        };
    }, [props.autoDismiss, props.dismissTimeout, visible, dismissed, props.onDismiss]);

    useEffect((): void => {
        const alertClasses: string[] = ['alert', 'mx-3'];

        alertClasses.push(props.alert.buildBootstrapClassName());

        if (visible === true) {
            alertClasses.push('fade show');
        } else {
            alertClasses.push('fade');
        }

        setAlertClass(alertClasses.join(' '));
    }, [props.alert]);

    return (
        <>
            {dismissed === false &&
                <div className={alertClass} role="alert">
                    {props.alert.message}
                </div>
            }
        </>
    );
};

AlertBox.defaultProps = {
    autoDismiss: true,
    dismissTimeout: 5000
};

export default AlertBox;
