import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
    isAuthenticated: Function;
}

const ProtectedRoute = (props: ProtectedRouteProps): React.JSX.Element => {
    if (props.isAuthenticated() === false) {
        return <Navigate to="/" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
