import LoginForm from './Login/LoginForm';
import Login from '../Entity/Authentication/Login';
import FormData from '../Entity/Form/FormData';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import EmailValidationDefinition from '../FormValidationHandler/EmailValidationDefinition';
import AuthenticationService from '../Authentication/AuthenticationService';
import AuthenticationRequest from '../Entity/Authentication/AuthenticationRequest';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import AlertBox from '../../Component/AlertBox';
import SpinnerOverlay from '../../Component/SpinnerOverlay';
import logo from '../../img/Kochapp.png';
import React, {useState} from 'react';
import {Link, NavigateFunction, useNavigate} from 'react-router-dom';
import Authentication from '../Entity/Authentication/Authentication';

const login: Login = new Login();
const authenticationService: AuthenticationService = new AuthenticationService();

const StartPage = (): React.JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<FormData<Login>>({data: login});
    const [alert, setAlert] = useState<Alert|null>(null);
    const navigate: NavigateFunction = useNavigate();

    const fieldValidationDefinitions: FieldValidationDefinition<Login>[] = [
        new RequiredValidationDefinition<Login>('identifier', 'Es muss eine E-Mail-Adresse angegeben werden.'),
        new EmailValidationDefinition<Login>('identifier', 'Das Format der E-Mail-Adresse ist ungültig.'),
        new RequiredValidationDefinition<Login>('password', 'Es muss ein Passwort angegeben werden.'),
    ];

    const formValidationHandler: FormValidationHandler<Login> = new FormValidationHandler<Login>(fieldValidationDefinitions);

    const submitForm = async (): Promise<void> => {
        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            return;
        }

        setAlert(null);
        setIsLoading(true);

        const authenticationRequest: AuthenticationRequest = {
            identifier: formData.data.identifier,
            password: formData.data.password
        }

        const authentication: Authentication|null = await authenticationService.authenticateUser(authenticationRequest);

        if (authentication === null) {
            setAlert(new Alert(AlertType.Error, 'Benutzername und / oder Kennwort waren falsch.'));
        } else {
            formData.data.identifier = '';
            formData.data.password = '';

            navigate('/dashboard');

            return;
        }

        setIsLoading(false);
    }
    return (
        <>
            {isLoading === true &&
                <SpinnerOverlay />
            }
            <div className="startPageBackground container-fluid">
                <img src={logo} className="startPageLogo col-lg-2 col-md-2 d-none d-md-block d-lg-block"  alt="neXCuisine" />
                <div className="row justify-content-end vh-100">
                    <div className="backgroundColorPrimary40 mt-5 shadow-lg col-xs-10 col-sm-10 col-md-3 col-lg-4 col-xl-3">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fs-4 mt-2 lightColor">Essensplaner und Rezeptsammlung</h1>
                            </div>
                            {alert !== undefined && alert !== null &&
                                <div className="col-12">
                                    <AlertBox alert={alert} />
                                </div>
                            }
                            <LoginForm formData={formData} setFormData={setFormData} formValidationHandler={formValidationHandler} />
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="col-sm-6 col-md-12 col-6 pt-1 text-start px-md-3">
                                        <button className="btn btn-primary w-100" onClick={submitForm}>LOGIN</button>
                                    </div>
                                    <div className="col-sm-6 col-md-12 col-6 pt-1 text-start px-md-3">
                                        <Link to="/registrierung" className="btn btn-secondary w-100" type="button">REGISTRIEREN</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col backgroundColorTransparentWhite30 mt-3 mb-3">
                                <Link to="/passwort-vergessen" type="button">
                                    <small>Passwort vergessen?</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
        </>
    );
};

export default StartPage;


