import React, {MouseEventHandler} from "react";
import {Card} from "react-bootstrap";
import ButtonWithIcon from "../../../Component/ButtonWithIcon";
import DialogOverlay from "../../../Component/DialogOverlay";
import IconButton from "../../../Component/IconButton";

interface DeleteDialogProps {
    readonly header: string;
    readonly body: string;
    readonly show: boolean;
    readonly callbackShowHide: Function;
    readonly callbackDelete: MouseEventHandler<Element>;
}

const DeleteDialog = (props: DeleteDialogProps): React.JSX.Element => {

    function hideDialog(): void
    {
        props.callbackShowHide(false);
    }
    function showDialog(): void
    {
        props.callbackShowHide(true);
    }

    if (props.show === false) {
        return (
            <IconButton icon="bi-trash3" iconType="Bootstrap" additionalClassName="delete-button ms-2" onClick={showDialog} />
        );
    }

    return (
        <DialogOverlay>
            <Card>
                <Card.Header>
                    Sie sind im Begriff diesen Benutzer zu löschen
                </Card.Header>
                <Card.Body>
                    Dieser Vorgang kann nicht rückgängig gemacht werden!
                </Card.Body>
                <Card.Footer>
                    <ButtonWithIcon icon="bi-exclamation-diamond" text="Löschen" onClick={props.callbackDelete} iconType="Bootstrap" additionalClassName="delete-button fa-pull-right" />
                    <ButtonWithIcon icon="bi-x-circle" text="Abbrechen" onClick={hideDialog} iconType="Bootstrap" additionalClassName="fa-pull-right" />
                </Card.Footer>
            </Card>
        </DialogOverlay>
    );
}

export default DeleteDialog;