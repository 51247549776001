import React, {FormEvent, useEffect, useState} from "react";
import FormDataObject from "../../../Entity/Form/FormData";
import FormValidationHandler from "../../../FormValidationHandler/FormValidationHandler";
import Dish from "../../../Entity/Dish/Dish";
import InputField from "../../../../Component/Form/Field/InputField";
import Authentication from "../../../Entity/Authentication/Authentication";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import Autocomplete from "../../../../Component/Form/Field/Autocomplete";
import AutocompleteItem from "../../../../Component/Form/Field/AutocompleteItem";
import Recipe from "../../../Entity/Recipe/Recipe";
import RecipeCard from "./RecipeCard";
import DishService from "../../../Dish/DishService";
import DishRequest from "../../../Entity/Dish/DishRequest";
import RecipeRequest from "../../../Entity/Recipe/RecipeRequest";
import RecipeService from "../../../Recipe/RecipeService";
import SpinnerOverlay from "../../../../Component/SpinnerOverlay";
import {AxiosResponse} from "axios";
import {NavigateFunction, useNavigate} from "react-router-dom";
import DishRecipe from "../../../Entity/Dish/DishRecipe";
import DishRecipeRequest from "../../../Entity/Dish/DishRecipeRequest";
import RecipeCategory from "../../../Entity/RecipeCategory/RecipeCategory";
import RecipeCategoryRequest from "../../../Entity/RecipeCategory/RecipeCategoryRequest";

interface DishFormProps {
    readonly formData: FormDataObject<Dish>;
    readonly formValidationHandler: FormValidationHandler<Dish>;
    readonly setFormData: Function;
    readonly autocompleteItems: AutocompleteItem[];
}

const DishForm = (props: DishFormProps): React.JSX.Element => {
    const dish: Dish = props.formData.data;
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [recipes, setRecipes] = useState<DishRecipe[]>(dish.recipes);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate: NavigateFunction = useNavigate();

    useEffect((): void => {
        dish.recipes = recipes;
    }, [recipes]);


    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (dish as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: dish});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    const submitForm = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        let redirect: boolean = false;
        setIsLoading(true);

        e.preventDefault();

        if (authentication === null) {
            return;
        }
        const dishService: DishService = new DishService(authentication);

        const dishRequest: DishRequest = {
            id: ((dish.id !== undefined) ? dish.id : null),
            name: dish.name,
            recipes: []
        }

        dish.recipes.map(
            function (dishRecipe: DishRecipe): void {
                const dishRecipeRequest: DishRecipeRequest =
                    {
                        id: dishRecipe.id,
                        name: dishRecipe.name,
                        difficultyLevel: dishRecipe.difficultyLevel,
                        preparationTime: dishRecipe.preparationTime,
                        cookingTime: dishRecipe.cookingTime,
                        kilocalories: dishRecipe.kilocalories,
                        position: dishRecipe.position,
                        description: dishRecipe.description,
                        persons: dishRecipe.persons,
                        categories: [],
                    };

                dishRecipe.recipeCategories.map(
                    function (category: RecipeCategory): void
                    {
                        dishRecipeRequest.categories.push({recipeCategory: category.recipeCategory});
                    }
                );

                dishRequest.recipes.push(dishRecipeRequest);
            }
        );

        if(dish.id === undefined) {
            const response: AxiosResponse = await dishService.post(dishRequest);
            dish.id = parseInt(response.headers['x-id']);
            redirect = true;
        } else {
            await dishService.put(dishRequest);
        }

        if (redirect) {
            navigate('/gerichte/editor/' + dish.id);
        }

        setIsLoading(false);
    }
    function autocompleteSelectCallback(name: string, element: any|undefined): void
    {
        if (element === undefined) {
            return;
        }
        let exists: boolean = false;
        const recipe: DishRecipe = element;
        const r: DishRecipe[] = [];

        dish.recipes.map(
            function (existingRecipe: DishRecipe): void {
                if (existingRecipe.id === recipe.id) {
                    exists = true;
                }
                r.push(existingRecipe);
            }
        );

        if (exists) {
            return;
        }

        dish.recipes.push(recipe);
        r.push(recipe);
        setRecipes(r);

    }

    function removeRecipeFromDish(recipe: DishRecipe): void
    {
        const r: DishRecipe[] = [];

        dish.recipes.map(
            function (existingRecipe: DishRecipe): void {
                if (recipe.id === existingRecipe.id) {
                    return;
                }
                r.push(existingRecipe);
            }
        );

        setRecipes(r);
    }

    function moveRecipeUp(dishRecipe: DishRecipe): void
    {
        const r: DishRecipe[] = [];
        let index: number|null = null;

        dish.recipes.map(
            function (existingRecipe: DishRecipe, i: number): void {
                r.push(existingRecipe);
                if (existingRecipe.id === dishRecipe.id) {
                    index = i;
                }
            }
        );

        if (index === null) {
            return;
        }
        let position: number = r[index - 1].position;

        r[index - 1].position = dishRecipe.position;
        dishRecipe.position = position;

        r[index] = r[index - 1];
        r[index - 1] = dishRecipe;

        setRecipes(r);

        dish.recipes = r;
    }

    function moveRecipeDown(dishRecipe: DishRecipe): void
    {
        const r: DishRecipe[] = [];
        let index: number|null = null;

        dish.recipes.map(
            function (existingRecipe: DishRecipe, i: number): void {
                r.push(existingRecipe);
                if (existingRecipe.id === dishRecipe.id) {
                    index = i;
                }
            }
        );

        if (index === null) {
            return;
        }
        let position: number = r[index + 1].position;

        r[index + 1].position = dishRecipe.position;
        dishRecipe.position = position;

        r[index] = r[index + 1];
        r[index + 1] = dishRecipe;

        setRecipes(r);

        dish.recipes = r;
    }

    return (
        <>
            {isLoading &&
                <SpinnerOverlay />
            }
            <form onSubmit={submitForm} id="dishForm">
                <div className="row">
                    <div className="col-12">
                        <InputField
                            name="name"
                            label="Bezeichnung"
                            type="text"
                            value={dish.name}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'name')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" id="ingredientAutocomplete">
                        <Autocomplete
                            name="recipe"
                            label="Rezept"
                            type="text"
                            value=""
                            required={false}
                            suggestions={props.autocompleteItems}
                            onSelect={autocompleteSelectCallback}
                            showAddButton={true}
                            resetOnEnter={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {recipes !== undefined &&
                            <div className="d-flex flex-wrap gap-1">
                                {recipes.map((recipe: Recipe, index: number): React.JSX.Element => (
                                    <div className="p-2">
                                        <RecipeCard
                                            recipe={recipe}
                                            index={index}
                                            onMoveUp={(index > 0) ? moveRecipeUp : undefined}
                                            onMoveDown={(index < recipes.length - 1) ? moveRecipeDown : undefined}
                                            onRemove={removeRecipeFromDish}
                                        />
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </form>
        </>
    );
}

export default DishForm;