import React, {ChangeEvent, useEffect, useState} from 'react';
import FieldLabel from "./Form/Field/FieldLabel";
import FieldStyle from "./Form/Field/Field.module.scss";
import FormError from "../Domain/Entity/Form/FormError";

type TimePickerProps = {
    value: number;
    label: string;
    name: string;
    required?: boolean;
    description?: string;
    onChange: (seconds: number) => void;
    readonly formErrors?: FormError[];
};

const TimePicker = (props: TimePickerProps): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;
    const [hours, setHours] = useState<number|null>(Math.floor(props.value / 3600));
    const [minutes, setMinutes] = useState<number|null>(Math.floor((props.value % 3600) / 60));


    useEffect((): void => {
        setHours(Math.floor(props.value / 3600));
        setMinutes(Math.floor((props.value % 3600) / 60));
    }, [props.value]);

    useEffect((): void => {
        const totalSeconds: number = ((hours !== null) ? hours : 0) * 3600 + ((minutes !== null) ? minutes : 0) * 60;
        props.onChange(totalSeconds);
    }, [hours, minutes]);

    function handleChangeHours(event: ChangeEvent<HTMLInputElement>): void
    {
        if (event.target.value === '') {
            setHours(null);
            return;
        }
        setHours(Math.max(0, parseInt(event.target.value, 10)));
    }

    function handleChangeMinutes(event: ChangeEvent<HTMLInputElement>): void
    {
        if (event.target.value === '') {
            setMinutes(null);
            return;
        }
        setMinutes(Math.max(0, Math.min(59, parseInt(event.target.value, 10))));

    }

    return (
        <>
            <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2 form-field-label-text" />
            <div className="row">
                <div className="col-5 p-0 ps-2">
                    <label className="w-100">
                        <input
                            type="number"
                            className={[FieldStyle.container, hasErrors === true ? ' is-invalid form-control' : ''].join(' ')}
                            value={(hours !== null) ? hours : ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeHours(e)}
                            min="0"
                        />
                    </label>
                </div>
                <div className="col-1 d-flex align-items-end p-0">Std.</div>
                <div className="col-5 p-0 ps-2">
                    <label className="w-100">
                        <input
                            type="number"
                            className={[FieldStyle.container, hasErrors === true ? ' is-invalid form-control' : ''].join(' ')}
                            value={(minutes !== null) ? minutes : ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeMinutes(e)}
                            min="0"
                        />
                    </label>
                </div>
                <div className="col-1 d-flex align-items-end p-0">Min.</div>
            </div>
        </>
    );
};

export default TimePicker;
