import WorkingStepResponse from "./WorkingStepResponse";

class WorkingStep {
    private _id: number;
    private _description: string;

    public static createFromWorkingStepResponse(workingStepResponse: WorkingStepResponse): WorkingStep
    {
        const workingStep: WorkingStep = new WorkingStep();

        workingStep._id = workingStepResponse.id;
        workingStep._description = workingStepResponse.description;

        return workingStep;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }
}

export default WorkingStep;