import AccountUserRoleResponse from "./AccountUserRoleResponse";

class AccountUserRole {
    private _role: string;
    private _name: string;

    static createFromAccountUserRoleResponse(accountUserRoleResponse: AccountUserRoleResponse): AccountUserRole
    {
        const accountUserRole: AccountUserRole = new AccountUserRole();

        accountUserRole._role = accountUserRoleResponse.role;
        accountUserRole._name = accountUserRoleResponse.name;

        return accountUserRole;
    }

    get role(): string {
        return this._role;
    }

    set role(value: string) {
        this._role = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
}

export default AccountUserRole;