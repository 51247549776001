import RecipeReaction from "../../../Entity/Recipe/RecipeReaction";
import React, {useEffect, useState} from "react";
import AccountUser from "../../../Entity/AccountUser/AccountUser";
import ReactionType from "../../../Entity/Enums/ReactionType";
import IconButton from "../../../../Component/IconButton";
import Recipe from "../../../Entity/Recipe/Recipe";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import Authentication from "../../../Entity/Authentication/Authentication";
import RecipeReactionService from "../../../Recipe/RecipeReactionService";
import {AxiosResponse} from "axios";

interface RecipeReactionElementInterface {
    recipeReactions: RecipeReaction[];
    accountUser: AccountUser;
    recipe: Recipe;
}

const RecipeReactionElement = (props: RecipeReactionElementInterface): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [accountUserRecipeReaction, setAccountUserRecipeReaction] = useState<RecipeReaction|null|undefined>(undefined);
    const [accountUserReactionType, setAccountUserReactionType] = useState<ReactionType|null|undefined>(undefined);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();

    useEffect((): void => {
        if (accountUserRecipeReaction !== undefined) {
            return;
        }

        props.recipeReactions.map(
            (recipeReaction: RecipeReaction): void => {
                if (recipeReaction.accountUser.id === props.accountUser.id) {
                    setAccountUserRecipeReaction(recipeReaction);
                    setAccountUserReactionType(recipeReaction.reactionType);
                }
            }
        );

    }, [accountUserRecipeReaction]);

    async function sendRecipeReaction(recipeReaction: RecipeReaction): Promise<void>
    {
        if (authentication === null) {
            return;
        }

        const recipeReactionService: RecipeReactionService = new RecipeReactionService(authentication);

        if (recipeReaction.id === null) {
            const response: AxiosResponse = await recipeReactionService.post(recipeReaction);
            recipeReaction.id = parseInt(response.headers['x-id']);
        } else {
            recipeReactionService.put(recipeReaction);
        }

        setAccountUserRecipeReaction(null);
        setAccountUserRecipeReaction(recipeReaction);
        setAccountUserReactionType(recipeReaction.reactionType);
    }

    function createNewRecipeReaction(): RecipeReaction
    {
        const recipeReaction: RecipeReaction = new RecipeReaction();

        recipeReaction.id = null;
        recipeReaction.accountUser = props.accountUser;
        recipeReaction.recipe = props.recipe;

        return recipeReaction;
    }

    const thumbDown = (): void => {
        const recipeReaction: RecipeReaction = (accountUserRecipeReaction !== null && accountUserRecipeReaction !== undefined)
            ? accountUserRecipeReaction
            : createNewRecipeReaction()
        ;

        recipeReaction.reactionType = ReactionType.BAD;

        sendRecipeReaction(recipeReaction);
    }

    const thumbNeutral = (): void => {
        const recipeReaction: RecipeReaction = (accountUserRecipeReaction !== null && accountUserRecipeReaction !== undefined)
            ? accountUserRecipeReaction
            : createNewRecipeReaction()
        ;

        recipeReaction.reactionType = ReactionType.NEUTRAL;

        sendRecipeReaction(recipeReaction);
    }

    const thumbUp = (): void => {
        const recipeReaction: RecipeReaction = (accountUserRecipeReaction !== null && accountUserRecipeReaction !== undefined)
            ? accountUserRecipeReaction
            : createNewRecipeReaction()
        ;

        recipeReaction.reactionType = ReactionType.GOOD;

        sendRecipeReaction(recipeReaction);
    }

    const thumbDisabled = (): void => {

    }

    if (accountUserReactionType === null || accountUserReactionType === undefined) {
        return (
            <div className="d-flex">
                <div>
                    <IconButton icon="thumb_down" onClick={thumbDown} iconType="Material" />
                </div>
                <div className="ms-3">
                    <IconButton icon="thumbs_up_down" onClick={thumbNeutral} iconType="Material" />
                </div>
                <div className="ms-3">
                    <IconButton icon="thumb_up" onClick={thumbUp} iconType="Material" />
                </div>
            </div>
        );
    }

    if (accountUserReactionType === ReactionType.GOOD) {
        return (
            <div className="d-flex">
                <div>
                    <IconButton icon="thumb_down" onClick={thumbDown} iconType="Material"/>
                </div>
                <div className="ms-3">
                    <IconButton icon="thumbs_up_down" onClick={thumbNeutral} iconType="Material"/>
                </div>
                <div className="ms-3">
                    <IconButton icon="thumb_up" onClick={thumbDisabled} iconType="Material" additionalClassName="backgroundInfo"/>
                </div>
            </div>
        );
    }

    if (accountUserReactionType === ReactionType.NEUTRAL) {
        return (
            <div className="d-flex">
                <div>
                    <IconButton icon="thumb_down" onClick={thumbDown} iconType="Material"/>
                </div>
                <div className="ms-3">
                    <IconButton icon="thumbs_up_down" onClick={thumbDisabled} iconType="Material" additionalClassName="backgroundInfo"/>
                </div>
                <div className="ms-3">
                    <IconButton icon="thumb_up" onClick={thumbUp} iconType="Material"/>
                </div>
            </div>
        );
    }

    if (accountUserReactionType === ReactionType.BAD) {
        return (
            <div className="d-flex">
                <div>
                    <IconButton icon="thumb_down" onClick={thumbDisabled} iconType="Material" additionalClassName="backgroundInfo"/>
                </div>
                <div className="ms-3">
                    <IconButton icon="thumbs_up_down" onClick={thumbNeutral} iconType="Material"/>
                </div>
                <div className="ms-3">
                    <IconButton icon="thumb_up" onClick={thumbUp} iconType="Material"/>
                </div>
            </div>
        );
    }

    return (<></>);
}

export default RecipeReactionElement;