enum Unit {
    GRAM = 1,
    KILOGRAM = 2,
    MILLIGRAM = 3,
    OUNCE = 4,
    POUND = 5,
    LITRE = 6,
    MILLILITRE = 7,
    CUP = 8,
    TEASPOON = 9,
    TABLESPOON = 10,
    PIECE = 11,
    PINCH = 12,
    TINS = 13,
}

export default Unit;