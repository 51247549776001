import AccountUserResponse from "./AccountUserResponse";

class AccountUser {

    private  _id: number|null = null;

    private _email: string;

    private _password: string | null;

    private _passwordRetype: string | null;

    private _firstname: string | null;

    private _name: string | null;

    private _birthday: string | null;

    private _roles: string[];

    private _enabled: boolean;

    static createFromAccountUserResponse(accountUserResponse: AccountUserResponse): AccountUser
    {
        let accountUser:AccountUser = new AccountUser();
        accountUser._id = accountUserResponse.id;
        accountUser._firstname = accountUserResponse.firstname;
        accountUser._name = accountUserResponse.name;
        accountUser._email = accountUserResponse.email;
        accountUser._birthday = accountUserResponse.birthday;
        accountUser._roles = accountUserResponse.roles;
        accountUser._enabled = accountUserResponse.enabled;

        return accountUser;
    }

    get id(): number|null {
        return this._id;
    }

    set id(value: number|null) {
        this._id = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get password(): string | null {
        return this._password;
    }

    set password(value: string | null) {
        this._password = value;
    }

    get passwordRetype(): string | null {
        return this._passwordRetype;
    }

    set passwordRetype(value: string | null) {
        this._passwordRetype = value;
    }

    get firstname(): string | null {
        return this._firstname;
    }

    set firstname(value: string | null) {
        this._firstname = value;
    }

    get name(): string | null {
        return this._name;
    }

    set name(value: string | null) {
        this._name = value;
    }

    get birthday(): string | null {
        return this._birthday;
    }

    set birthday(value: string | null) {
        this._birthday = value;
    }

    get roles(): string[] {
        return this._roles;
    }

    set roles(value: string[]) {
        this._roles = value;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }
}

export default AccountUser;
