
class Breadcrumb {
    private _path: string;
    private _label: string;

    private static getNameForPath(path: string, part: string): string
    {
        if (path === '/') {
            return 'Dashbord';
        }

        return part.charAt(0).toUpperCase() + part.slice(1);
    }

    public static createFromStringArray(paths: string[], maxDepth: number): Breadcrumb[]
    {
        const breadcrumbs: Breadcrumb[] = [];
        const len: number = paths.length;
        let path: string = '/';
        let breadcrumb: Breadcrumb = new Breadcrumb();

        breadcrumb._label = Breadcrumb.getNameForPath(path, path);
        breadcrumb._path = path;
        breadcrumbs.push(breadcrumb);

        for (let i=0; i < len; i++) {

            if (i + 1 >= maxDepth) {
                break;
            }

            path += paths[i];

            breadcrumb = new Breadcrumb();
            breadcrumb._label = Breadcrumb.getNameForPath(path, paths[i]);
            breadcrumb._path = path;
            breadcrumbs.push(breadcrumb);

            path += '/';
        }

        return breadcrumbs;
    }

    get path(): string {
        return this._path;
    }

    get label(): string {
        return this._label;
    }
}

export default Breadcrumb;