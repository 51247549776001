import Login from '../../Entity/Authentication/Login';
import FormData from '../../Entity/Form/FormData';
import InputField from '../../../Component/Form/Field/InputField';
import FormValidationHandler from '../../FormValidationHandler/FormValidationHandler';
import React from 'react';

interface LoginFormProps {
    readonly formData: FormData<Login>;
    readonly setFormData: Function;

    readonly formValidationHandler?: FormValidationHandler<Login>;
}

const LoginForm = (props: LoginFormProps): React.JSX.Element => {
    const login: Login = props.formData.data;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (login as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: login});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <div className="mb-4">
                <InputField
                    name="identifier"
                    label="E-Mail-Adresse"
                    type="email"
                    value={login.identifier}
                    onChange={handleChange}
                    required={true}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'identifier')}
                />
            </div>
            <div className="mb-4">
                <InputField
                    name="password"
                    label="Passwort"
                    type="password"
                    value={login.password}
                    onChange={handleChange}
                    required={true}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'password')}
                />
            </div>
        </>
    );
};

export default LoginForm;
