import ApiV1Provider from "../../Api/ApiV1Provider";
import Authentication from '../Entity/Authentication/Authentication';
import {AxiosResponse} from "axios/index";
import {AxiosRequestConfig} from "axios";

class FileService {

    private readonly apiV1Provider: ApiV1Provider;

    private static cachedUrls: {id: number, url: string}[] = [];
    private static cachedScaledImageUrls: {id: number, width: number, height: number, url: string}[] = [];

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
    }

    private static getCached(id: number): string|null {

        for (let i: number = 0; i < FileService.cachedUrls.length; i++) {
            if (FileService.cachedUrls[i].id === id) {
                return FileService.cachedUrls[i].url;
            }
        }

        return null;
    }

    private static getScaledImageCached(id: number, width: number, height: number): string|null {

        for (let i: number = 0; i < FileService.cachedScaledImageUrls.length; i++) {
            if (
                FileService.cachedScaledImageUrls[i].id === id &&
                FileService.cachedScaledImageUrls[i].width === width &&
                FileService.cachedScaledImageUrls[i].height === height
            ) {
                return FileService.cachedScaledImageUrls[i].url;
            }
        }

        return null;
    }

    public async getFileData(id: number): Promise<string|undefined> {
        let url: string|null = FileService.getCached(id);

        if (url !== null) {
            return url;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/files/' + id.toString(), {
                headers: {
                    'Authorization': 'Bearer ' + this.apiV1Provider.token,
                }
            });

            if (!response.ok) {
                throw new Error('Fehler beim Laden des Bildes');
            }

            const bildBlob: Blob = await response.blob();
            url = URL.createObjectURL(bildBlob);
            FileService.cachedUrls.push(
                {id: id, url: url}
            );
            return url;
        } catch (error) {
            console.error('Fehler:', error);
        }
    }

    public async getScaledImage(id: number, width: number, height: number): Promise<string|undefined> {
        let url: string|null = FileService.getScaledImageCached(id, width, height);

        if (url !== null) {
            return url;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/files/resized/' + width.toString() + 'X' + height.toString() +'/' + id.toString(), {
                headers: {
                    'Authorization': 'Bearer ' + this.apiV1Provider.token,
                }
            });

            if (!response.ok) {
                throw new Error('Fehler beim Laden des Bildes');
            }

            const bildBlob = await response.blob();
            url = URL.createObjectURL(bildBlob);

            FileService.cachedScaledImageUrls.push(
                {
                    id: id,
                    width: width,
                    height: height,
                    url: url
                }
            );

            return url;
        } catch (error) {
            console.error('Fehler:', error);
        }
    }

    public async post(formData: FormData): Promise<AxiosResponse>  {

        const config: AxiosRequestConfig<FormData> = {headers: {'Content-Type': 'multipart/form-data'}};

        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/files', formData, config);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default FileService;