import React, {useEffect, useState} from "react";
import SpinnerOverlay from "../../../Component/SpinnerOverlay";
import Breadcrumb from "../Component/Breadcrumb";
import Board from "../Component/Board";
import AuthenticationService from "../../Authentication/AuthenticationService";
import Authentication from "../../Entity/Authentication/Authentication";
import FloatingIcon from "../../../Component/FloatingIcon";
import {NavigateFunction, useNavigate} from "react-router-dom";
import Dish from "../../Entity/Dish/Dish";
import DishService from "../../Dish/DishService";
import DishCard from "./Component/DishCard";

interface DishOverviewProps {

}

const DishOverview = (props: DishOverviewProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dishes, setDishes] = useState<Dish[]|undefined>(undefined);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const navigate: NavigateFunction = useNavigate();

    async function loadDishes(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        setIsLoading(true);
        const dishService: DishService = new DishService(authentication);

        setDishes(await dishService.get());
        setIsLoading(false);
    }

    useEffect((): void  => {
        if (dishes !== undefined) {
            return;
        }

        loadDishes();

    }, [dishes]);

    function openEditor(): void {
        navigate('/gerichte/editor');
    }

    return (
        <>
            {isLoading === true &&
                <SpinnerOverlay />
            }
            <Breadcrumb></Breadcrumb>
            <Board>
                {((): React.JSX.Element => {
                    if (dishes !== undefined && dishes.length > 0) {
                        return (
                            <div className="row">
                                {dishes.map((dish: Dish): React.JSX.Element => (
                                    <div className="col-xxl-3 col-xl-4 col-12 pt-3">
                                        <DishCard dish={dish} />
                                    </div>
                                ))}
                            </div>
                        );
                    } else {
                        return (<>Keine Gerichte</>);
                    }
                })()}
                <FloatingIcon onClick={openEditor} icon="fa-plus" iconType="FontAwsome"  align="right" />
            </Board>
        </>
        );
}

export default DishOverview;