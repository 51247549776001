import React, {useEffect, useState} from "react";
import AuthenticationService from "../../Authentication/AuthenticationService";
import AccountUser from "../../Entity/AccountUser/AccountUser";
import Authentication from "../../Entity/Authentication/Authentication";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import AccountUserService from "../../AccountUser/AccountUserService";
import SpinnerOverlay from "../../../Component/SpinnerOverlay";
import Breadcrumb from "../Component/Breadcrumb";
import Board from "../Component/Board";
import AccountUserForm from "./Component/AccountUserForm";
import IconButton from "../../../Component/IconButton";
import FloatingBottomBar from "../../../Component/FloatingBottomBar";
import FormData from "../../Entity/Form/FormData";
import FormValidationHandler from "../../FormValidationHandler/FormValidationHandler";
import FieldValidationDefinition from "../../FormValidationHandler/FieldValidationDefinition";
import RequiredValidationDefinition from "../../FormValidationHandler/RequiredValidationDefinition";
import EmailValidationDefinition from "../../FormValidationHandler/EmailValidationDefinition";
import PasswordValidationDefinition from "../../FormValidationHandler/PasswordValidationDefinition";
import PasswordRepetitionValidationDefinition from "../../FormValidationHandler/PasswordRepetitionValidationDefinition";
import DialogOverlay from "../../../Component/DialogOverlay";
import {Card} from "react-bootstrap";
import ButtonWithIcon from "../../../Component/ButtonWithIcon";

interface AccountUserEditorProps {

}

const AccountUserEditor = (props: AccountUserEditorProps): React.JSX.Element => {
    const fieldValidationDefinitions: FieldValidationDefinition<AccountUser>[] = [
        new RequiredValidationDefinition<AccountUser>('name', 'Geben Sie bitte einen Nachnamen an.'),
        new RequiredValidationDefinition<AccountUser>('firstname', 'Geben Sie bitte einen Vornamen an.'),
        new RequiredValidationDefinition<AccountUser>('email', 'Geben Sie bitte eine gültige Email Adresse an.'),
        new EmailValidationDefinition<AccountUser>('email', 'Geben Sie bitte eine gültige Email Adresse an.'),
        new PasswordValidationDefinition<AccountUser>('password','Geben Sie bitte ein Kennwort an, welches min. 8 Zeichen lang ist, eine Zahl, ein Großbuchstaben und ein Sonderzeichen hat.'),
        new PasswordRepetitionValidationDefinition<AccountUser>('password','passwordRetype', 'Kennwörter stimmen nicht überein.'),
        new PasswordRepetitionValidationDefinition<AccountUser>('passwordRetype','password', 'Kennwörter stimmen nicht überein.'),
    ];
    const formValidationHandler: FormValidationHandler<AccountUser> = new FormValidationHandler<AccountUser>(fieldValidationDefinitions);
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [accountUser, setAccountUser] = useState<AccountUser|null|undefined>(undefined);
    const navigate: NavigateFunction = useNavigate();
    const {accountUserId} = useParams<string>();
    const [showDeleteDialog,setShowDeleteDialog] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData<AccountUser>|undefined>(undefined);

    useEffect((): void => {

        if (accountUser !== undefined) {
            return;
        }

        if (accountUserId === undefined) {
            const r: AccountUser = new AccountUser();
            setAccountUser(r);
            const data: FormData<AccountUser> = {data: r, formValidationHandler: formValidationHandler};
            setFormData(data);
            return;
        }
        loadAccountUserFormData();

    }, [accountUser]);

    async function loadAccountUserFormData(): Promise<void>
    {
        if(authentication === null || accountUserId === undefined) {
            return;
        }
        const accountUserService: AccountUserService = new AccountUserService(authentication);
        const accountUser: AccountUser = await accountUserService.fetchById(parseInt(accountUserId));

        setAccountUser(accountUser);
        const data: FormData<AccountUser> = {data: accountUser, formValidationHandler: formValidationHandler};
        setFormData(data);
    }

    async function loadAccountUser(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const accountUserService: AccountUserService = new AccountUserService(authentication);
        const accountUserObject: AccountUser = await accountUserService.profile();

        if (accountUserService.isGranted('ROLE_ADMINISTRATOR', accountUserObject.roles) === false) {
            navigate('/dashboard');
            return;
        }
        setAccountUser(accountUserObject);
    }

    useEffect((): void => {
        if (accountUser !== undefined || authentication === null) {
            return;
        }
        loadAccountUser();

    }, [accountUser, authentication]);

    function submitForm(): void
    {
        const form: HTMLFormElement = document.querySelector('#accountUserForm') as HTMLFormElement;
        const event: Event = new Event('submit', { bubbles: true, cancelable: true });

        if (form.reportValidity() === false) {
            return;
        }
        form.dispatchEvent(event);
    }

    function showDeleteAccountUser(): void
    {
        setShowDeleteDialog(true);
    }

    async function deleteAccountUser(): Promise<void>
    {
        if (authentication === null || accountUserId === undefined) {
            return;
        }

        const accountUserService: AccountUserService = new AccountUserService(authentication);
        await accountUserService.delete(parseInt(accountUserId));

        navigate('/anwender');
    }

    function hideDeleteAccountUser(): void
    {
        setShowDeleteDialog(false);
    }

    if (formData === undefined || accountUser === undefined) {
        return (
            <SpinnerOverlay />
        );
    }

    return (
        <>
            {isLoading === true &&
                <SpinnerOverlay />
            }
            {showDeleteDialog === true &&
                <DialogOverlay>
                    <Card>
                        <Card.Header>
                            Sie sind im Begriff diesen Benutzer zu löschen
                        </Card.Header>
                        <Card.Body>
                            Dieser Vorgang kann nicht rückgängig gemacht werden!
                        </Card.Body>
                        <Card.Footer>
                            <ButtonWithIcon icon="bi-exclamation-diamond" text="Löschen" onClick={deleteAccountUser} iconType="Bootstrap" additionalClassName="delete-button fa-pull-right" />
                            <ButtonWithIcon icon="bi-x-circle" text="Abbrechen" onClick={hideDeleteAccountUser} iconType="Bootstrap" additionalClassName="fa-pull-right" />
                        </Card.Footer>
                    </Card>
                </DialogOverlay>
            }
            <Breadcrumb>
                {accountUserId === undefined &&
                    <>Anlegen</>
                }
                {accountUserId !== undefined &&
                    <>Bearbeiten</>
                }
            </Breadcrumb>
            <Board>
                <AccountUserForm setFormData={setFormData} formValidationHandler={formValidationHandler} formData={formData} />
                <FloatingBottomBar>
                    <IconButton icon="fa-floppy-disk" iconType="FontAwsome" onClick={submitForm} />
                    {accountUserId !== undefined &&
                        <IconButton icon="bi-trash3" iconType="Bootstrap" additionalClassName="delete-button ms-2" onClick={showDeleteAccountUser} />
                    }
                </FloatingBottomBar>
            </Board>
        </>
    );
}

export default AccountUserEditor;