import RecipeCategory from '../../../Entity/RecipeCategory/RecipeCategory';
import basicRecipes from '../../../../img/category/grundrezepte.jpeg';
import startersAndSalads from '../../../../img/category/vorspeisen_und_salte.png';
import soup from '../../../../img/category/suppen.jpg';
import starters from '../../../../img/category/vorspeise.jpg';
import meat from '../../../../img/category/fleisch.jpg';
import fish from '../../../../img/category/fisch.jpg';
import vegetables from '../../../../img/category/gemuese.jpg';
import others from '../../../../img/category/sonstige.jpg';
import sides from '../../../../img/category/beilagen.jpg';
import soucen from '../../../../img/category/soucen.jpeg';
import desserts from '../../../../img/category/dessert.jpg';
import bakingSweet from '../../../../img/category/backen_suess.webp';
import bakingSavoury from '../../../../img/category/backen_herzhaft.webp';
import bread from '../../../../img/category/brot_broetchen.jpg';
import drinks from '../../../../img/category/getraenke.jpg';
import React from 'react';
import {Link} from 'react-router-dom';

interface CategoryProps {
    recipeCategory: RecipeCategory;
}

const Category = (props: CategoryProps): React.JSX.Element => {

    function getImageFromRecipeCategory(recipeCategory: RecipeCategory): string
    {
        if (recipeCategory.recipeCategory === 1) {
            return basicRecipes as string;
        }

        if (recipeCategory.recipeCategory === 2) {
            return startersAndSalads as string;
        }

        if (recipeCategory.recipeCategory === 3) {
            return soup as string;
        }

        if (recipeCategory.recipeCategory === 4) {
            return starters as string;
        }

        if (recipeCategory.recipeCategory === 5) {
            return meat as string;
        }

        if (recipeCategory.recipeCategory === 6) {
            return fish as string;
        }

        if (recipeCategory.recipeCategory === 7) {
            return vegetables as string;
        }

        if (recipeCategory.recipeCategory === 8) {
            return others as string;
        }

        if (recipeCategory.recipeCategory === 9) {
            return sides as string;
        }

        if (recipeCategory.recipeCategory === 10) {
            return soucen as string;
        }

        if (recipeCategory.recipeCategory === 11) {
            return desserts as string;
        }

        if (recipeCategory.recipeCategory === 12) {
            return bakingSweet as string;
        }

        if (recipeCategory.recipeCategory === 13) {
            return bakingSavoury as string;
        }

        if (recipeCategory.recipeCategory === 14) {
            return bread as string;
        }

        if (recipeCategory.recipeCategory === 15) {
            return drinks as string;
        }

        return '';
    }
    return (
        <div className="col-xxl-3 col-xl-4 col-12 pt-3">
            <Link to={"/rezepte/" + props.recipeCategory.recipeCategory} className="category-card">
                <div className="card shadow-lg highlight">
                    <img src={getImageFromRecipeCategory(props.recipeCategory)} className="card-img card-img-category" alt={props.recipeCategory.name}/>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-8 category-name">
                                {props.recipeCategory.name}
                            </div>
                            <div className="col-4 category-recipes-count d-flex align-items-end">
                                {((props.recipeCategory.countRecipes !== null && props.recipeCategory.countRecipes > 0)
                                    ? ((props.recipeCategory.countRecipes > 1) ? props.recipeCategory.countRecipes + ' Rezepte' : '1 Rezept')
                                    : 'Keine Rezepte')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default Category;