import React, {PropsWithChildren} from "react";

interface SuccessMessageBoxProps extends PropsWithChildren {

}

const SuccessMessageBox = (props: SuccessMessageBoxProps): React.JSX.Element => {

    return (
        <div className="successMessageBox p-3">
            {props.children}
        </div>
    );
}

export default SuccessMessageBox;