import React from "react";
import Picture from "../../Entity/Picture/Picture";
import PictureView from "./PictureView";

interface PictureCardProps {
    picture: Picture;
}

const PictureCard = (props: PictureCardProps): React.JSX.Element => {
    return (
        <div className="col-xxl-2 col-md-3 col-12 card-shadow card mx-sm-3 mx-1 p-1">
            <div className="card-img-top">
                <PictureView picture={props.picture} className={"card-img card-img-category"}/>
            </div>
            <div className="card-footer"></div>
        </div>
    );
}

export default PictureCard;