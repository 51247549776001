import React, {PropsWithChildren} from "react";

interface DialogOverlayProps extends PropsWithChildren {
}

const DialogOverlay = (props: DialogOverlayProps): React.JSX.Element => {
    return (
        <div className="loadingSpinnerContainer d-flex justify-content-center align-items-center">
            {props.children}
        </div>
    );
}

export default DialogOverlay;