import ComponentResponse from "./ComponentResponse";
import Ingredient from "./Ingredient";
import IngredientResponse from "./IngredientResponse";
import WorkingStep from "./WorkingStep";
import WorkingStepResponse from "./WorkingStepResponse";

class Component {
    private _id: number;
    private _name: string;
    private _ingredients: Ingredient[];
    private _workingSteps: WorkingStep[];


    static createFromComponentResponse(componentResponse: ComponentResponse): Component {

        const component: Component = new Component();
        component._id = componentResponse.id;
        component._name = componentResponse.name;
        component._ingredients = [];
        component._workingSteps = [];

        componentResponse.ingredients.map((ingredientResponse: IngredientResponse): void => {
            component._ingredients.push(Ingredient.createFromIngredientResponse(ingredientResponse));
        });

        componentResponse.workingSteps.map((workingStepResponse: WorkingStepResponse): void => {
            component._workingSteps.push(WorkingStep.createFromWorkingStepResponse(workingStepResponse));
        });

        return component;
    }

    constructor() {
        this._ingredients = [];
        this. _workingSteps = [];
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get ingredients(): Ingredient[] {
        return this._ingredients;
    }

    set ingredients(value: Ingredient[]) {
        this._ingredients = value;
    }

    get workingSteps(): WorkingStep[] {
        return this._workingSteps;
    }

    set workingSteps(value: WorkingStep[]) {
        this._workingSteps = value;
    }
}

export default Component;