import React from "react";
import {Spinner} from "react-bootstrap";

const SpinnerOverParent = (): React.JSX.Element => {
    return (
        <div className="overlayContainer">
            <Spinner />
        </div>
    );
};

export default SpinnerOverParent;
