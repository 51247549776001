import RecipeCategory from "../RecipeCategory/RecipeCategory";
import RecipeResponse from "./RecipeResponse";
import RecipeCategoryResponse from "../RecipeCategory/RecipeCategoryResponse";
import Recipe from "./Recipe";
import {types} from "sass";
import DifficultyLevel from "../Enums/DifficultyLevel";
import Error = types.Error;

abstract class AbstractRecipe {
    protected _id: number;
    protected _name: string;
    protected _difficultyLevel: DifficultyLevel;
    private _preparationTime: number|null;
    private _cookingTime: number|null;
    private _kilocalories: number|null;
    protected _description: string|null;
    protected _persons: number;
    protected _recipeCategories: RecipeCategory[];
    private _accountUserId: number;

    static fillFromRecipeResponse(recipe: AbstractRecipe, recipeResponse: RecipeResponse): Recipe
    {
        if (recipeResponse.id === null) {
            throw new Error('recipe response id is null');
        }

        recipe._id = recipeResponse.id;
        recipe._name = recipeResponse.name;
        recipe._difficultyLevel = recipeResponse.difficultyLevel as DifficultyLevel;
        recipe._cookingTime = recipeResponse.cookingTime;
        recipe._preparationTime = recipeResponse.preparationTime;
        recipe._kilocalories = recipeResponse.kilocalories;
        recipe._description = recipeResponse.description;
        recipe._persons = recipeResponse.persons;
        recipe._recipeCategories = [];
        recipe._accountUserId = recipeResponse.accountUserId;

        recipeResponse.categories.map((recipeCategoryResponse: RecipeCategoryResponse ): void => {
            recipe._recipeCategories.push(RecipeCategory.createFromRecipeCategoryResponse(recipeCategoryResponse));
        });

        return recipe;
    }

    constructor() {
        this._accountUserId = 0;
        this._recipeCategories = [];
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get difficultyLevel(): DifficultyLevel {
        return this._difficultyLevel;
    }

    set difficultyLevel(value: DifficultyLevel) {
        this._difficultyLevel = value;
    }

    get preparationTime(): number | null {
        return this._preparationTime;
    }

    set preparationTime(value: number | null) {
        this._preparationTime = value;
    }

    get cookingTime(): number | null {
        return this._cookingTime;
    }

    set cookingTime(value: number | null) {
        this._cookingTime = value;
    }

    get kilocalories(): number | null {
        return this._kilocalories;
    }

    set kilocalories(value: number | null) {
        this._kilocalories = value;
    }

    get description(): string|null {
        return this._description;
    }

    set description(value: string|null) {
        this._description = value;
    }

    get persons(): number {
        return this._persons;
    }

    set persons(value: number) {
        this._persons = value;
    }

    get recipeCategories(): RecipeCategory[] {
        return this._recipeCategories;
    }

    set recipeCategories(value: RecipeCategory[]) {
        this._recipeCategories = value;
    }

    get accountUserId(): number {
        return this._accountUserId;
    }

    set accountUserId(value: number) {
        this._accountUserId = value;
    }
}

export default AbstractRecipe;