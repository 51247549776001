import React, {useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import CSS from 'csstype';
import {Blob} from "node:buffer";

const thumbsContainer: CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '16px'
};

const thumb: CSS.Properties = {
    display: 'inline-flex',
    borderRadius: '2',
    border: '1px solid #eaeaea',
    marginBottom: '8',
    marginRight: '8',
    width: '100px',
    height: '100px',
    padding: '4',
    boxSizing: 'border-box'
};

const thumbInner: CSS.Properties = {
    display: 'flex',
    minWidth: '0',
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

interface DropZoneProps extends React.PropsWithChildren {
    callback: any;
    disabled: boolean;
    title: string;
    multiple?: boolean;
    triggerClearEvent: boolean;
}

interface DropzoneFile extends Blob {
    readonly lastModified: number;
    readonly name: string;
    readonly webkitRelativePath: string;
    readonly preview: string;
}


export default function DropZone(props: DropZoneProps) {

    const [files, setFiles] = useState<DropzoneFile[]>([]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: (acceptedFiles: File[]): void => {
            setFiles(acceptedFiles.map((file: File): DropzoneFile => {
                return Object.assign(file, {preview: URL.createObjectURL(file)}) as DropzoneFile;
            }));
            props.callback(acceptedFiles);
        },
        multiple: (props.multiple !== undefined) ? props.multiple : true
    });

    const thumbs = files.map((file: DropzoneFile) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        return () => files.forEach((file: DropzoneFile) => URL.revokeObjectURL(file.preview));
    }, files);

    useEffect((): void => {
        if (props.triggerClearEvent === true) {
            setFiles([]);
        }

    }, [props.triggerClearEvent]);

    return (
        <>
            {React.Children.count(props.children) > 0 &&
                <>{props.children}</>
            }
            <div>
                {(props.disabled !== false) ? ( <span className="spinner-border spinner-border-sm mr-1"></span> ) : (
                    <div {...getRootProps({className: 'dropzone'})} className="dropzone-container">
                        <input {...getInputProps()} />
                        <p>{props.title}</p>
                        <aside style={thumbsContainer}>
                            {thumbs}
                        </aside>
                    </div>
                )}
            </div>
        </>
    );
}