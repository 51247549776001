import RecipeCategoryResponse from "./RecipeCategoryResponse";

class RecipeCategory {
    private _recipeCategory: number;
    private _name: string;
    private _countRecipes: number|null;

    static createFromRecipeCategoryResponse(recipeCategoryResponse: RecipeCategoryResponse): RecipeCategory
    {
        let recipeCategory:RecipeCategory = new RecipeCategory();
        recipeCategory._recipeCategory = recipeCategoryResponse.recipeCategory;
        recipeCategory._name = recipeCategoryResponse.name;
        recipeCategory._countRecipes = recipeCategoryResponse.countRecipes;

        return recipeCategory;
    }

    get recipeCategory(): number {
        return this._recipeCategory;
    }
    get name(): string {
        return this._name;
    }

    get countRecipes(): number | null {
        return this._countRecipes;
    }
}

export default RecipeCategory;