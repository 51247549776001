import ApiV1Provider from '../../Api/ApiV1Provider';
import Authentication from '../Entity/Authentication/Authentication';
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import AccountUserResponse from "../Entity/AccountUser/AccountUserResponse";
import AccountUser from "../Entity/AccountUser/AccountUser";
import LocalStorageService from "../LocalStorage/LocalStorageService";
import AccountUserRole from "../Entity/AccountUser/AccountUserRole";
import AccountUserRoleResponse from "../Entity/AccountUser/AccountUserRoleResponse";
import AccountUserRequest from "../Entity/AccountUser/AccountUserRequest";

class AccountUserService {
    private readonly apiV1Provider: ApiV1Provider
    private readonly localStorageService: LocalStorageService;

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
        this.localStorageService = new LocalStorageService();
    }

    public async get(): Promise<AccountUser[]> {
        const accountUsers: AccountUser[] = [];
        try {
            const response: AxiosResponse<AccountUserResponse[]> = await this.apiV1Provider.get('/users');
            const accountUserResponses: AccountUserResponse[] = response.data;

            accountUserResponses.map(
                (accountUserResponse: AccountUserResponse): void => {
                    accountUsers.push(AccountUser.createFromAccountUserResponse(accountUserResponse));
                }
            );

            return accountUsers;
        } catch (error) {
            throw error;
        }
    }

    public async post(accountUserRequest: AccountUserRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/users', accountUserRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async put(accountUserRequest: AccountUserRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.put('/users/' + accountUserRequest.id, accountUserRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async delete(accountUserId: number): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.delete('/users/' + accountUserId);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async getAccountUserRoles(): Promise<AccountUserRole[]> {
        const accountUserRoles: AccountUserRole[] = [];
        try {
            const response: AxiosResponse<AccountUserRoleResponse[]> = await this.apiV1Provider.get('/users/roles');
            const accountUserRoleResponses: AccountUserRoleResponse[] = response.data;

            accountUserRoleResponses.map(
                (accountUserRoleResponse: AccountUserRoleResponse): void => {
                    accountUserRoles.push(AccountUserRole.createFromAccountUserRoleResponse(accountUserRoleResponse));
                }
            );

            return accountUserRoles;
        } catch (error) {
            throw error;
        }
    }

    public async fetchById(id: number): Promise<AccountUser> {
        try {
            const response: AxiosResponse<AccountUserResponse> = await this.apiV1Provider.get('/users/' + id.toString());
            const accountUserResponse: AccountUserResponse = response.data;

            return AccountUser.createFromAccountUserResponse(accountUserResponse);
        } catch (error) {
            throw error;
        }
    }

    public async profile(): Promise<AccountUser> {
        const accountUserResponse: AccountUserResponse|null = this.localStorageService.getFromStorage<AccountUserResponse>('accountUser');

        if (accountUserResponse !== null) {
            return AccountUser.createFromAccountUserResponse(accountUserResponse);
        }

        try {
            const response: AxiosResponse<AccountUserResponse> = await this.apiV1Provider.get('/users/profile');
            const accountUser: AccountUser = AccountUser.createFromAccountUserResponse(response.data);
            this.localStorageService.saveToStorage<AccountUserResponse>('accountUser', response.data, 7);

            return accountUser;
        } catch (error) {
            throw error;
        }
    }

    public isGranted(attribute: string, subject: string[]): boolean
    {
        for (let i: number = 0; i < subject.length; i++) {
            if (attribute === subject[i]) {
                return true;
            }
        }

        return false;
    }
}

export default AccountUserService;
