import ApiV1Provider from "../../Api/ApiV1Provider";
import Authentication from '../Entity/Authentication/Authentication';
import RecipeRequest from '../Entity/Recipe/RecipeRequest';
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import Picture from "../Entity/Picture/Picture";
import PictureResponse from "../Entity/Picture/PictureResponse";
import FileService from "../File/FileService";

class PictureService {
    private readonly apiV1Provider: ApiV1Provider;

    private readonly fileService: FileService;

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
        this.fileService = new FileService(authentication);
    }

    public async getByRecipeId(id: number): Promise<Picture[]> {
        const pictures: Picture[] = [];
        try {
            const response: AxiosResponse<PictureResponse[]> = await this.apiV1Provider.get('/pictures/recipe/' + id);
            const pictureResponses: PictureResponse[] = response.data;

            pictureResponses.forEach((pictureResponse: PictureResponse): void => {
                pictures.push(Picture.createFromPictureResponse(pictureResponse));
            });

            return pictures;
        } catch (error) {
            throw error;
        }
    }

    public async postToRecipe(formData: FormData): Promise<AxiosResponse>  {

        const config: AxiosRequestConfig<FormData> = {headers: {'Content-Type': 'multipart/form-data'}};


        if (typeof formData.get('id') !== 'string') {
            throw new Error('id is invalid');
        }

        let id: string = formData.get('id') as string;

        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/pictures/recipe/' + id, formData, config);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async deleteFromRecipe(recipeId: number, id: number): Promise<AxiosResponse>  {

        const config: AxiosRequestConfig<RecipeRequest> = {headers: {'Content-Type': 'application/json'}};

        try {
            const response: AxiosResponse = await this.apiV1Provider.delete('/pictures/recipe/' + recipeId + '/' + id, config);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async getImageUrl(picture: Picture): Promise<string|undefined> {
        if (picture.file.id === null) {
            return undefined;
        }
        return await this.fileService.getFileData(picture.file.id);
    }

    public async getScaledImageUrl(picture: Picture, width: number, height: number): Promise<string|undefined> {
        if (picture.file.id === null) {
            return undefined;
        }
        return await this.fileService.getScaledImage(picture.file.id, width, height);
    }
}

export default PictureService;