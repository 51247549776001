import ApiV1Provider from "../../Api/ApiV1Provider";
import Authentication from "../Entity/Authentication/Authentication";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import Component from "../Entity/Recipe/Component";
import ComponentRequest from "../Entity/Recipe/ComponentRequest";
import ComponentResponse from "../Entity/Recipe/ComponentResponse";

class ComponentService {
    private readonly  apiV1Provider: ApiV1Provider

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
    }

    public async fetchByRecipeId(recipeId: number): Promise<Component[]> {
        const components: Component[] = [];
        try {
            const response: AxiosResponse<ComponentResponse[]> = await this.apiV1Provider.get('/recipes/' + recipeId + '/components');
            const recipeCategoryResponses: ComponentResponse[] = response.data;

            recipeCategoryResponses.forEach((componentResponse: ComponentResponse): void => {
                components.push(Component.createFromComponentResponse(componentResponse));
            });

            return components;
        } catch (error) {
            throw error;
        }
    }

    public async post(recipeId: number, componentRequest: ComponentRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/recipes/' + recipeId + '/components', componentRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async put(recipeId: number, componentRequest: ComponentRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.put('/recipes/' + recipeId + '/components/' + componentRequest.id, componentRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async delete(recipeId: number, componentId: number): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.delete('/recipes/' + recipeId + '/components/' + componentId);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default ComponentService;