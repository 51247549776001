import React, {PropsWithChildren} from "react";

interface NotificationMessageBoxProps extends PropsWithChildren {

}

const NotificationMessageBox = (props: NotificationMessageBoxProps): React.JSX.Element => {

    return (
        <div className="notificationMessageBox p-3">
            {props.children}
        </div>
    );
}

export default NotificationMessageBox;