import React, {PropsWithChildren, useEffect, useState} from "react";

interface OverlayMessageProps extends PropsWithChildren {
    onClose?: Function;
    timeout: number;
    visible: boolean
}

const OverlayMessage = (props: OverlayMessageProps): React.JSX.Element => {
    const [visible, setVisible] = useState<boolean>(props.visible);

    useEffect((): () => void => {
        let timeout: NodeJS.Timeout | number | undefined = undefined;

        if (visible === true) {
            timeout = setTimeout((): void => {
                setVisible(false);

                if (props.onClose !== undefined) {
                    props.onClose();
                }

                setTimeout((): void => {
                    setVisible(false);
                }, 500);
            }, props.timeout);
        }

        return (): void => {
            if (timeout !== undefined) {
                clearTimeout(timeout);
            }
        };
    }, [props.timeout, visible]);

    useEffect((): void => {

        setVisible(props.visible);

    }, [props.visible]);

    if (visible === false) {
        return (<></>);
    }

    return (
        <div className="loadingSpinnerContainer d-flex justify-content-center align-items-center">
            {props.children}
        </div>
    );
}

export default OverlayMessage;