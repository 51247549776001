import FieldLabelStyle from './FieldLabel.module.scss';
import React from 'react';

interface FieldLabelProps extends React.PropsWithChildren {
    readonly label: string;
    readonly htmlFor: string;
    readonly required?: boolean;
    readonly description?: string;
    readonly className?: string;
}

const FieldLabel = (props: FieldLabelProps): React.JSX.Element => {
    return (
        <label htmlFor={props.htmlFor} className={[FieldLabelStyle.fieldLabel, props.className].join(' ')}>
            {props.label}
            {props.required === true && <sup>*</sup>}
            {props.description !== undefined &&
               <></>
            }
            {props.children}
        </label>
    );
};

export default FieldLabel;
