import React, {FormEvent, useState} from "react";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import FormDataObject from "../../../Entity/Form/FormData";
import AccountUser from "../../../Entity/AccountUser/AccountUser";
import InputField from "../../../../Component/Form/Field/InputField";
import FormValidationHandler from "../../../FormValidationHandler/FormValidationHandler";
import SpinnerOverlay from "../../../../Component/SpinnerOverlay";
import AccountUserRequest from "../../../Entity/AccountUser/AccountUserRequest";

interface AccountUserRegisterForm {
    readonly formData: FormDataObject<AccountUser>;
    readonly formValidationHandler: FormValidationHandler<AccountUser>;
    readonly setFormData: Function;
    readonly submittedCallback?: Function;
}

const AccountUserRegisterForm = (props: AccountUserRegisterForm): React.JSX.Element => {
    const accountUser: AccountUser = props.formData.data;
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading,setIsLoading] = useState<boolean>(false);

    const submitForm = async (e: FormEvent<HTMLFormElement>): Promise<void> => {

        e.preventDefault();

        if (accountUser.password === null) {
            return;
        }

        setIsLoading(true);

        const accountUserRequest: AccountUserRequest = {
            id: accountUser.id,
            email: accountUser.email,
            password: accountUser.password,
            firstname: accountUser.firstname,
            name: accountUser.name,
            birthday: accountUser.birthday,
            roles: [],
        };

        authenticationService.registerAccountUser(accountUserRequest);

        setIsLoading(false);

        if (props.submittedCallback !== undefined) {
            props.submittedCallback();
        }

    }

    const handleDateChange = (date: Date|null): void => {
        if (date === null) {
            (accountUser as any)['birthday'] = '';
            return;
        }

        accountUser.birthday = date.toDateString();

        updateFormData();
    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (accountUser as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: accountUser});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };


    return (
        <>
            {isLoading &&
                <SpinnerOverlay />
            }
            <form onSubmit={submitForm} id="accountUserForm">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <InputField
                            name="name"
                            label="Name"
                            type="text"
                            value={(accountUser.name !== null) ? accountUser.name : ''}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'name')}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <InputField
                            name="firstname"
                            label="Vorname"
                            type="text"
                            value={(accountUser.firstname !== null) ? accountUser.firstname : ''}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'firstname')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <InputField
                            name="email"
                            label="Email"
                            type="email"
                            value={(accountUser.email !== null) ? accountUser.email : ''}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'email')}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <InputField
                            name="birthday"
                            label="Geburstag"
                            type="date"
                            value={(accountUser.birthday !== null) ? accountUser.birthday : ''}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'email')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <InputField
                            name="password"
                            label="Kennwort"
                            type="password"
                            value={(accountUser.password !== null) ? accountUser.password : ''}
                            onChange={handleChange}
                            required={accountUser.id === null}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'password')}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <InputField
                            name="passwordRetype"
                            label="Kennwort wiederholen"
                            type="password"
                            value={(accountUser.passwordRetype !== null) ? accountUser.passwordRetype : ''}
                            onChange={handleChange}
                            required={accountUser.id === null}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'passwordRetype')}
                        />
                    </div>
                </div>
            </form>
        </>);
}

export default AccountUserRegisterForm;