import React, {useState} from "react";
import Ingredient from "../../../Entity/Recipe/Ingredient";
import Unit from "../../../Entity/Enums/Unit";
import FormData from "../../../Entity/Form/FormData";
import FieldValidationDefinition from "../../../FormValidationHandler/FieldValidationDefinition";
import RequiredValidationDefinition from "../../../FormValidationHandler/RequiredValidationDefinition";
import NumericValidationDefinition from "../../../FormValidationHandler/NumericValidationDefinition";
import FormValidationHandler from "../../../FormValidationHandler/FormValidationHandler";
import UnitService from "../../../Ingredient/UnitService";
import SelectField from "../../../../Component/Form/Field/SelectField";
import SelectOption from "../../../Entity/Form/SelectOption";
import InputField from "../../../../Component/Form/Field/InputField";
import FontAwesomeIcon from "../../../../Component/FontAwesomeIcon";
import AutocompleteItem from "../../../../Component/Form/Field/AutocompleteItem";
import Autocomplete from "../../../../Component/Form/Field/Autocomplete";
import Component from "../../../Entity/Recipe/Component";

interface IngredientFormElementProps {
    readonly ingredient: Ingredient,
    readonly component: Component,
    readonly index: number,
    readonly onRemove?: Function,
    readonly ingredientAutocompleteSuggestions: AutocompleteItem[]
}

const IngredientForm = (props: IngredientFormElementProps): React.JSX.Element => {
    const [ingredient, setIngredient] = useState<Ingredient>(props.ingredient);
    const fieldValidationDefinitions: FieldValidationDefinition<Ingredient>[] = [
        new RequiredValidationDefinition<Ingredient>('name', 'Geben Sie bitte eine Bezeichnung an.'),
        new RequiredValidationDefinition<Ingredient>('quantity', 'Geben Sie bitte die Menge an.'),
        new NumericValidationDefinition<Ingredient>('quantity', 'Die Menge muss eine Zahl sein.'),
    ];
    const formValidationHandler: FormValidationHandler<Ingredient> = new FormValidationHandler<Ingredient>(fieldValidationDefinitions);
    const [formData, setFormData] = useState<FormData<Ingredient>>({data: ingredient});
    const unitService: UnitService = new UnitService();

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        const name: string = event.target.name;
        (ingredient as any)[name] = event.target.value;
        props.component.ingredients[props.index] = ingredient;

        updateFormData();
        validateField(name);
    };

    const handleUnitChange = (selectedValue: SelectOption<Unit> | null): void => {
        if (selectedValue === null) {
            return;
        }
        ingredient.unit = selectedValue.value;
        props.component.ingredients[props.index] = ingredient;

        setFormData({...formData, data: ingredient});
    }

    const updateFormData = (): void => {
        setFormData({...formData, data: ingredient});
    };

    const validateField = (fieldName: string): void => {
        if (formValidationHandler === undefined) {
            return;
        }

        formValidationHandler.validateField(fieldName, formData);
        setFormData({...formData, errors: formData.errors});
    };

    const onRemoveClick = (): void => {
        if (props.onRemove === undefined) {
            return;
        }

        props.onRemove(props.index);
    }

    function autocompleteSelectCallback(name: string, element: any|undefined): void
    {
        if (element === undefined) {
            return;
        }

        ingredient.unit = (element as Ingredient).unit;
        props.component.ingredients[props.index] = ingredient;

        setFormData({...formData, data: ingredient});
    }

    function onClickAutocomplete(element: any|undefined): void
    {
        if (element === undefined) {
            return;
        }

        ingredient.unit = (element.element as Ingredient).unit;
        props.component.ingredients[props.index] = ingredient;

        setFormData({...formData, data: ingredient});
    }

    return (
        <form>
            {props.index > 0 &&
                <div className="row">
                    <div className="col row-seperator mt-3 mb-2"></div>
                </div>
            }
            <div className="row">
                <div className="col-4 col-md-3">
                    <InputField
                        id={"quantity_" + props.index}
                        name={"quantity"}
                        label="Menge"
                        type="text"
                        value={(ingredient.quantity > 0) ? ingredient.quantity : ''}
                        onChange={handleChange}
                        required={true}
                        formErrors={FormValidationHandler.getFieldErrors(formData, 'quantity')}
                    />
                </div>
                <div className="col-6 col-md-3 select-wrapper">
                    <SelectField
                        name={"unit"}
                        label="Einheit"
                        required={true}
                        options={unitService.getUnitsAsOptions()}
                        value={{label: unitService.getNameFromUnit(ingredient.unit), value: ingredient.unit}}
                        placeholder="Bitte wählen"
                        onChange={handleUnitChange}
                        formErrors={FormValidationHandler.getFieldErrors(formData, 'unit')}
                    />
                </div>
                <div className="col-10 col-md-4">

                    {false &&
                    <InputField
                        name={"name"}
                        label="Bezeichnung"
                        type="text"
                        value={ingredient.name}
                        onChange={handleChange}
                        required={true}
                        formErrors={FormValidationHandler.getFieldErrors(formData, 'name')}
                    />
                    }
                        <Autocomplete
                            name="name"
                            label="Bezeichnung"
                            type="text"
                            value={ingredient.name}
                            onChange={handleChange}
                            required={true}
                            suggestions={props.ingredientAutocompleteSuggestions}
                            onSelect={autocompleteSelectCallback}
                            onClickSuggestion={onClickAutocomplete}
                            showAddButton={false}
                            resetOnEnter={false}
                        />

                </div>
                {props.onRemove !== undefined &&
                    <div className="col-2 d-flex justify-content-center align-items-end h4">
                        <div className="pointer-cursor">
                            <FontAwesomeIcon iconClassName="fa-trash" onClick={onRemoveClick}/>
                        </div>
                    </div>
                }
            </div>
        </form>
    );
}

export default IngredientForm;