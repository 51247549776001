import React, {useEffect, useState} from "react";
import SpinnerOverlay from "../../../Component/SpinnerOverlay";
import logo from '../../../img/Kochapp.png';
import {Link, NavigateFunction, useNavigate, useParams} from "react-router-dom";
import AccountUserPasswordResetForm from "./Component/AccountUserPasswordResetForm";
import FieldValidationDefinition from "../../FormValidationHandler/FieldValidationDefinition";
import AccountUser from "../../Entity/AccountUser/AccountUser";
import RequiredValidationDefinition from "../../FormValidationHandler/RequiredValidationDefinition";
import EmailValidationDefinition from "../../FormValidationHandler/EmailValidationDefinition";
import PasswordValidationDefinition from "../../FormValidationHandler/PasswordValidationDefinition";
import PasswordRepetitionValidationDefinition from "../../FormValidationHandler/PasswordRepetitionValidationDefinition";
import FormValidationHandler from "../../FormValidationHandler/FormValidationHandler";
import FormData from "../../Entity/Form/FormData";
import AuthenticationService from "../../Authentication/AuthenticationService";
import OverlayMessage from "../../../Component/OverlayMessage";
import SuccessMessageBox from "../../../Component/SuccessMessageBox";

interface PasswordResetProps {

}

const PasswordReset = (props: PasswordResetProps): React.JSX.Element => {
    const [isLoading,setIsLoading] = useState<boolean>(false);
    const {uuid} = useParams<string>();
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [successMessageText, setSuccessMessageText] = useState<string|null>(null);

    const fieldValidationDefinitions: FieldValidationDefinition<AccountUser>[] = [
        new RequiredValidationDefinition<AccountUser>('email', 'Geben Sie bitte eine gültige Email Adresse an.'),
        new EmailValidationDefinition<AccountUser>('email', 'Geben Sie bitte eine gültige Email Adresse an.'),
        new PasswordValidationDefinition<AccountUser>('password','Geben Sie bitte ein Kennwort an, welches min. 8 Zeichen lang ist, eine Zahl, ein Großbuchstaben und ein Sonderzeichen hat.'),
        new PasswordRepetitionValidationDefinition<AccountUser>('password','passwordRetype', 'Kennwörter stimmen nicht überein.'),
        new PasswordRepetitionValidationDefinition<AccountUser>('passwordRetype','password', 'Kennwörter stimmen nicht überein.'),
    ];
    const formValidationHandler: FormValidationHandler<AccountUser> = new FormValidationHandler<AccountUser>(fieldValidationDefinitions);
    const [formData, setFormData] = useState<FormData<AccountUser>>({data: new AccountUser(), formValidationHandler: formValidationHandler});
    const [authenticatinService] = useState<AuthenticationService>(new AuthenticationService());
    const navigate: NavigateFunction = useNavigate();

    useEffect((): void => {

        if (successMessageText === null) {
            setShowOverlay(false);
            return;
        }

        setShowOverlay(true);

    }, [successMessageText]);

    const submitForm = async (): Promise<void> => {

        if (uuid === undefined) {
            formValidationHandler.removeFieldValidationDefinitionsByFieldName('password');
            formValidationHandler.removeFieldValidationDefinitionsByFieldName('passwordRetype');
        } else {
            formValidationHandler.removeFieldValidationDefinitionsByFieldName('email');
        }

        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            return;
        }

        setIsLoading(true);
        if (uuid === undefined) {
            await authenticatinService.accountUserPasswordRequest(
                {
                    email: formData.data.email
                }
            );
            setIsLoading(false);
            setSuccessMessageText(
                'Sie erhalten in Kürze eine E-Mail mit einem Link zum erstellen eines neuen Kennworts. '
                    + 'Bitte schauen Sie auch in ihren Spam Filter.'
            );
            return;
        }

        if (formData.data.password === null) {
            setIsLoading(false);
            return ;
        }

        await authenticatinService.accountUserPasswordReset(
            {
                password: formData.data.password
            },
            uuid
        );
        setIsLoading(false);
        setSuccessMessageText(
            'Ihr neues Kennwort wurde gespeichert, Sie können sich nun Anmelden.'
        );
    }

    const closeOverlay = (): void => {
        navigate('/');
    }

  return (
      <>
          {isLoading === true &&
              <SpinnerOverlay />
          }
          <div className="startPageBackground container-fluid">
              <img src={logo} className="startPageLogo col-lg-2 col-md-2 d-none d-md-block d-lg-block"  alt="neXCuisine" />
              <div className="row justify-content-end vh-100">
                  <div className="backgroundColorPrimary40 mt-5 shadow-lg col-xs-10 col-sm-10 col-md-3 col-lg-4 col-xl-3">
                      <div className="row">
                          <div className="col-12">
                              <h1 className="fs-4 mt-2 lightColor">Essensplaner und Rezeptsammlung</h1>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col backgroundColorTransparentWhite30 mt-3 mb-3 justify-content-center align-items-center">
                              - Kennwort vergessen -
                          </div>
                      </div>
                      <div className="row">
                          <AccountUserPasswordResetForm uuid={uuid} formData={formData} formValidationHandler={formValidationHandler} setFormData={setFormData} />
                          <div className="col-12">
                              <div className="row justify-content-center">
                                  <div className="col-sm-6 col-md-12 col-6 pt-1 text-start px-md-3">
                                      <button className="btn btn-primary w-100" onClick={submitForm}>
                                          {uuid === undefined &&
                                              <>Kennwort zurücksetzten anfordern</>
                                          }
                                          {uuid !== undefined &&
                                              <>Neues Kennwort setzen</>
                                          }
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col backgroundColorTransparentWhite30 mt-3 mb-3">
                              <Link to="/" type="button">
                                  <small>Zurück zum Login?</small>
                              </Link>
                          </div>
                      </div>
                  </div>
                  <div className="col-1"></div>
              </div>
          </div>
          <OverlayMessage timeout={3000} visible={showOverlay} onClose={closeOverlay}>
              <SuccessMessageBox>
                  {successMessageText}
              </SuccessMessageBox>
          </OverlayMessage>
      </>
  );
}

export default PasswordReset;