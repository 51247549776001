import React, {useEffect, useState} from "react";
import AuthenticationService from "../../Authentication/AuthenticationService";
import Authentication from "../../Entity/Authentication/Authentication";
import SpinnerOverlay from "../../../Component/SpinnerOverlay";
import Breadcrumb from "../Component/Breadcrumb";
import Board from "../Component/Board";
import FloatingBottomBar from "../../../Component/FloatingBottomBar";
import IconButton from "../../../Component/IconButton";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import FormData from "../../Entity/Form/FormData";
import Dish from "../../Entity/Dish/Dish";
import DeleteDialog from "../Component/DeleteDialog";
import FieldValidationDefinition from "../../FormValidationHandler/FieldValidationDefinition";
import RequiredValidationDefinition from "../../FormValidationHandler/RequiredValidationDefinition";
import FormValidationHandler from "../../FormValidationHandler/FormValidationHandler";
import DishForm from "./Component/DishForm";
import DishService from "../../Dish/DishService";
import Recipe from "../../Entity/Recipe/Recipe";
import RecipeService from "../../Recipe/RecipeService";
import AutocompleteItem from "../../../Component/Form/Field/AutocompleteItem";
import AccountUserService from "../../AccountUser/AccountUserService";

interface DishEditorProps {

}

const DishEditor = (props: DishEditorProps): React.JSX.Element => {
    const fieldValidationDefinitions: FieldValidationDefinition<Dish>[] = [
        new RequiredValidationDefinition<Dish>('name', 'Geben Sie bitte einen Namen an.'),
    ];
    const navigate: NavigateFunction = useNavigate();
    const formValidationHandler: FormValidationHandler<Dish> = new FormValidationHandler<Dish>(fieldValidationDefinitions);
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const {dishId} = useParams<string>();
    const [formData, setFormData] = useState<FormData<Dish>|undefined>(undefined);
    const [dish,setDish] = useState<Dish|undefined>(undefined);
    const [recipeAutocompleteItems, setRecipeAutocompleteItems] = useState<AutocompleteItem[]|undefined>(undefined);

    useEffect((): void => {
        if (dish !== undefined) {
            return;
        }

        if (dishId === undefined) {
            const r: Dish = new Dish();
            setDish(r);
            const data: FormData<Dish> = {data: r, formValidationHandler: formValidationHandler};
            setFormData(data);
            return;
        }

        loadDish();
    }, [dish]);

    useEffect((): void => {
        if (recipeAutocompleteItems !== undefined) {
            return;
        }

        loadRecipeAutocompleteItems();

    }, [recipeAutocompleteItems]);

    async function loadDish(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const dishService: DishService = new DishService(authentication);

        const r: Dish = (dishId === undefined) ? new Dish() : await dishService.getById(parseInt(dishId));
        setDish(r);
        const data: FormData<Dish> = {data: r, formValidationHandler: formValidationHandler};
        setFormData(data);
    }

    async function loadRecipeAutocompleteItems(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const recipeService: RecipeService = new RecipeService(authentication);
        const recipes: Recipe[] = await recipeService.fetchAll();
        const autocompleteItems: AutocompleteItem[] = [];

        recipes.map(
            function (recipe: Recipe): void {
                autocompleteItems.push(
                    {name: recipe.name, element: recipe}
                );
            },
            recipes
        );

        setRecipeAutocompleteItems(autocompleteItems);
    }

    if (formData === undefined || dish === undefined || recipeAutocompleteItems === undefined) {
        return (
            <SpinnerOverlay />
        );
    }
    function submitForm(): void
    {
        const form: HTMLFormElement = document.querySelector('#dishForm') as HTMLFormElement;
        const event: Event = new Event('submit', { bubbles: true, cancelable: true });

        if (form.reportValidity() === false) {
            return;
        }
        form.dispatchEvent(event);
    }

    function deleteDish(): void
    {
        if (authentication === null || dish === undefined || dish.id === null) {
            return;
        }

        const dishService: DishService = new DishService(authentication);

        dishService.delete(dish.id);
        navigate('/gerichte');

        setShowDeleteDialog(false);
    }

    return (
        <>
            {isLoading === true &&
                <SpinnerOverlay />
            }
            <Breadcrumb>
                {dishId === undefined &&
                    <>Anlegen</>
                }
                {dishId !== undefined &&
                    <>Bearbeiten</>
                }
            </Breadcrumb>
            <Board>
                <DishForm setFormData={setFormData} formValidationHandler={formValidationHandler} formData={formData} autocompleteItems={recipeAutocompleteItems} />
                <FloatingBottomBar>
                    <IconButton icon="fa-floppy-disk" iconType="FontAwsome" onClick={submitForm} />
                    {dishId !== undefined &&
                        <DeleteDialog
                            header="Gericht löschen"
                            body="Sie sind im Begriff dieses Gericht zu löschen."
                            show={showDeleteDialog}
                            callbackShowHide={setShowDeleteDialog}
                            callbackDelete={deleteDish}
                        />
                    }
                </FloatingBottomBar>
            </Board>
        </>
    );
}

export default DishEditor;