import AuthedHeader from './Controller/Component/AuthedHeader';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import React from 'react';

interface AuthedBaseLayoutProps extends React.PropsWithChildren {
}

const AuthedBaseLayout = (props: AuthedBaseLayoutProps): React.JSX.Element => {
    return (
        <>
            <Header>
                <AuthedHeader />
            </Header>
            <div className="body flex-grow-1 d-flex flex-row pt-3">
                <div id="contentWrapper" className="flex-column flex-grow-1">
                    <main className="position-relative flex-grow-1 d-flex flex-column">
                        {props.children}
                    </main>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default AuthedBaseLayout;
