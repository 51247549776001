import React from "react";
import {Carousel} from "react-bootstrap";
import FontAwesomeIcon from "./FontAwesomeIcon";

interface CardSliderProps extends React.PropsWithChildren {
    activeIndex?: number;
    onSelect?: (selectedIndex: number) => void;
}

const CardSlider = (props: CardSliderProps): React.JSX.Element => {
    const showControls: boolean = React.Children.count(props.children) > 1;

    function onSelect(i: number): void
    {
        if (props.onSelect === undefined) {
            return;
        }

        props.onSelect(i);
    }

    return (
        <Carousel
            interval={null}
            indicators={false}
            prevIcon={showControls ? <FontAwesomeIcon iconClassName={"fa-circle-chevron-left"} /> : null}
            nextIcon={showControls ? <FontAwesomeIcon iconClassName={"fa-circle-chevron-right"} /> : null}
            activeIndex={props.activeIndex}
            onSelect={onSelect}
        >
            {props.children}
        </Carousel>
    );
}

export default CardSlider;