import ApiV1Provider from "../../Api/ApiV1Provider";
import Authentication from "../Entity/Authentication/Authentication";
import {AxiosResponse} from "axios";
import RecipeRequest from "../Entity/Recipe/RecipeRequest";
import Recipe from "../Entity/Recipe/Recipe";
import RecipeResponse from "../Entity/Recipe/RecipeResponse";
import RecipeCategory from "../Entity/RecipeCategory/RecipeCategory";

class RecipeService {
    private readonly  apiV1Provider: ApiV1Provider

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
    }

    public async fetchById(id: number): Promise<Recipe> {
        try {
            const response: AxiosResponse<RecipeResponse> = await this.apiV1Provider.get('/recipes/' + id);
            return Recipe.createFromRecipeResponse(response.data);
        } catch (error) {
            throw error;
        }
    }

    public async fetchByCategory(id: number): Promise<Recipe[]> {
        const recipes: Recipe[] = [];

        try {
            const response: AxiosResponse<RecipeResponse[]> = await this.apiV1Provider.get('/recipes/categories/' + id + '/recipes');
            const recipeResponses: RecipeResponse[] = response.data;

            recipeResponses.forEach((recipeResponse: RecipeResponse): void => {
                recipes.push(Recipe.createFromRecipeResponse(recipeResponse));
            });

            return recipes;
        } catch (error) {
            throw error;
        }
    }

    public async fetchAll(): Promise<Recipe[]> {
        const recipes: Recipe[] = [];

        try {
            const response: AxiosResponse<RecipeResponse[]> = await this.apiV1Provider.get('/recipes');
            const recipeResponses: RecipeResponse[] = response.data;

            recipeResponses.forEach((recipeResponse: RecipeResponse): void => {
                recipes.push(Recipe.createFromRecipeResponse(recipeResponse));
            });

            return recipes;
        } catch (error) {
            throw error;
        }
    }

    public async post(recipeRequest: RecipeRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/recipes', recipeRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async put(recipeRequest: RecipeRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.put('/recipes/' + recipeRequest.id, recipeRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async delete(recipeId: number): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.delete('/recipes/' + recipeId);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public createRecipeRequestFromRecipe(recipe: Recipe): RecipeRequest
    {
        const recipeRequest: RecipeRequest = {
            id: recipe.id,
            name: recipe.name,
            difficultyLevel: recipe.difficultyLevel,
            preparationTime: recipe.preparationTime,
            cookingTime: recipe.cookingTime,
            kilocalories: recipe.kilocalories,
            description: recipe.description,
            persons: recipe.persons,
            categories: [],
        }

        recipe.recipeCategories.map((recipeCategory: RecipeCategory): void => {
            recipeRequest.categories.push({recipeCategory: recipeCategory.recipeCategory});
        });

        return recipeRequest;
    }
}

export default RecipeService;