import ApiV1Provider from '../../Api/ApiV1Provider';
import Authentication from '../Entity/Authentication/Authentication';
import {AxiosResponse} from 'axios';
import WorkingStep from "../Entity/Recipe/WorkingStep";
import WorkingStepResponse from "../Entity/Recipe/WorkingStepResponse";
import WorkingStepRequest from "../Entity/Recipe/WorkingStepRequest";

class WorkingStepService {
    private readonly apiV1Provider: ApiV1Provider

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
    }

    public async fetchByComponentId(recipeId: number, componentId: number): Promise<WorkingStep[]> {
        const workingSteps:WorkingStep[] = [];

        try {
            const response: AxiosResponse<WorkingStepResponse[]> = await this.apiV1Provider.get('/recipes/' + recipeId + '/components/' + componentId + '/ingredients');
            const workingStepResponses: WorkingStepResponse[] = response.data;
            workingStepResponses.forEach((workingStepResponse: WorkingStepResponse): void => {
                workingSteps.push(WorkingStep.createFromWorkingStepResponse(workingStepResponse));
            });
            return workingSteps;
        } catch (error) {
            throw error;
        }
    }

    public async post(recipeId: number, componentId: number, workingStepRequest: WorkingStepRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/recipes/' + recipeId + '/components/' + componentId + '/working-steps', workingStepRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async put(recipeId: number, componentId: number, workingStepRequest: WorkingStepRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.put('/recipes/' + recipeId + '/components/' + componentId + '/working-steps/' + workingStepRequest.id, workingStepRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async delete(recipeId: number, componentId: number, workingStepId: number): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.delete('/recipes/' + recipeId + '/components/' + componentId + '/working-steps/' + workingStepId);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default WorkingStepService;