import Recipe from "./Recipe";
import ReactionType from "../Enums/ReactionType";
import AccountUser from "../AccountUser/AccountUser";
import RecipeReactionResponse from "./RecipeReactionResponse";

class RecipeReaction {
    private _id: number | null;

    private _recipe: Recipe;

    private _reactionType: ReactionType;

    private _accountUser: AccountUser;

    public static createFromRecipeReactionResponse(recipeReactionResponse: RecipeReactionResponse): RecipeReaction
    {
        const recipeReaction: RecipeReaction = new RecipeReaction();

        recipeReaction._id = recipeReactionResponse.id;
        recipeReaction._recipe = Recipe.createFromRecipeResponse(recipeReactionResponse.recipe);
        recipeReaction._reactionType = recipeReactionResponse.reactionType;
        recipeReaction._accountUser = AccountUser.createFromAccountUserResponse(recipeReactionResponse.accountUser);

        return recipeReaction;
    }

    get id(): number | null {
        return this._id;
    }

    set id(value: number | null) {
        this._id = value;
    }

    get recipe(): Recipe {
        return this._recipe;
    }

    set recipe(value: Recipe) {
        this._recipe = value;
    }

    get reactionType(): ReactionType {
        return this._reactionType;
    }

    set reactionType(value: ReactionType) {
        this._reactionType = value;
    }

    get accountUser(): AccountUser {
        return this._accountUser;
    }

    set accountUser(value: AccountUser) {
        this._accountUser = value;
    }
}

export default RecipeReaction;