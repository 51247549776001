import React from 'react';
import {Outlet} from 'react-router-dom';
import AuthedBaseLayout from "./Domain/AuthedBaseLayout";

const App = (): React.JSX.Element => {
  return (
      <div className="background">
          <AuthedBaseLayout>
              <Outlet />
          </AuthedBaseLayout>
      </div>
  );
};

export default App;
