import File from "../File/File";
import PictureResponse from "./PictureResponse";

class Picture {
    private _id: number | null;
    private _recipeId: number;
    private _file: File;

    static createFromPictureResponse(pictureResponse: PictureResponse): Picture
    {
        const picture: Picture = new Picture();

        picture._id = pictureResponse.id;
        picture._recipeId = pictureResponse.recipeId;
        picture._file = File.createFromFileResponse(pictureResponse.file);

        return picture;
    }

    get id(): number | null {
        return this._id;
    }

    set id(value: number | null) {
        this._id = value;
    }

    get recipeId(): number {
        return this._recipeId;
    }

    set recipeId(value: number) {
        this._recipeId = value;
    }

    get file(): File {
        return this._file;
    }

    set file(value: File) {
        this._file = value;
    }
}

export default Picture;