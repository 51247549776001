import React, {MouseEventHandler} from "react";
import FontAwesomeIcon from "./FontAwesomeIcon";
import BootstrapIcon from "./BootstrapIcon";
import MaterialIcon from "./MaterialIcon";

type Position = 'left' | 'right';
type IconType = 'Bootstrap' | 'FontAwsome' | 'Material';

interface FloatingButtonProps {
    readonly icon: string;
    readonly align: Position;
    readonly onClick: MouseEventHandler;
    readonly iconType: IconType;
    readonly additionalClassName?: string;
}

const FloatingIcon = (props: FloatingButtonProps): React.JSX.Element => {

    if (props.align === 'right') {
        return (
            <div className={"floating-right-bottom" + ((props.additionalClassName !== undefined) ? ' ' + props.additionalClassName : '')} onClick={props.onClick}>
                {props.iconType === 'FontAwsome' &&
                    <FontAwesomeIcon iconClassName={props.icon} />
                }
                {props.iconType === 'Bootstrap' &&
                    <BootstrapIcon iconClassName={props.icon} />
                }
                {props.iconType === 'Material' &&
                    <MaterialIcon iconClassName={props.icon} />
                }
            </div>
        );
    } else {
        return (
            <div className="floating-left-bottom" onClick={props.onClick} >
                {props.iconType === 'FontAwsome' &&
                    <FontAwesomeIcon iconClassName={props.icon} />
                }
                {props.iconType === 'Bootstrap' &&
                    <BootstrapIcon iconClassName={props.icon} />
                }
                {props.iconType === 'Material' &&
                    <MaterialIcon iconClassName={props.icon} />
                }
            </div>
        );
    }

}

export default FloatingIcon;