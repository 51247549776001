import ApiV1Provider from "../../Api/ApiV1Provider";
import LocalStorageService from "../LocalStorage/LocalStorageService";
import Authentication from "../Entity/Authentication/Authentication";
import {AxiosResponse} from "axios";
import CalendarEntry from "../Entity/Calendar/CalendarEntry";
import CalendarEntryResponse from "../Entity/Calendar/CalendarEntryResponse";
import CalendarEntryRequest from "../Entity/Calendar/CalendarEntryRequest";
import AccountUser from "../Entity/AccountUser/AccountUser";
import AccountUserResponse from "../Entity/AccountUser/AccountUserResponse";

class CalendarService {

    private readonly apiV1Provider: ApiV1Provider
    private readonly localStorageService: LocalStorageService;

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
        this.localStorageService = new LocalStorageService();
    }

    public getCurrentWeekNumber(): number {
        const today: Date = new Date();
        const jahr: number = today.getFullYear();

        const timestampToday: number = today.getTime();
        const timestampStartOfYear: number = new Date(jahr, 0, 1).getTime();

        const dayOfTheYear: number = Math.floor((timestampToday - timestampStartOfYear) / 1000 / 60 / 60 / 24);
        const daysPerWeek: number = 7;
        const calendarWeek: number = 1 + Math.floor(dayOfTheYear / daysPerWeek);

        return calendarWeek;
    }

    public isWeekNumberValid(weekNumber: number): boolean {
        return (weekNumber > 0 && weekNumber < 54);
    }

    public getStartDateAndEndDateFromWeekNumberAndYear(weekNumber: number, year: number): {startDate: Date, endDate: Date}  {

        if (this.isWeekNumberValid(weekNumber) === false) {
            new Error();
        }

        const referenceDate = new Date(year, 0, 1);

        while (referenceDate.getDay() !== 1) {
            referenceDate.setDate(referenceDate.getDate() + 1);
        }

        const daysSinceReference: number = (weekNumber - 1) * 7;

        const startDate = new Date(referenceDate.getTime());
        startDate.setDate(startDate.getDate() + daysSinceReference);

        const weekDates: Date[] = [];

        for (let i: number = 0; i < 7; i++) {
            weekDates.push(new Date(startDate.getTime()));
            startDate.setDate(startDate.getDate() + 1);
        }

        return { startDate: weekDates[0], endDate: weekDates[6] };
    }

    public async getInDateRange(startDate: Date, endDate: Date): Promise<CalendarEntry[]> {
        const calendarEntries: CalendarEntry[] = [];
        const dateRange: string =
            startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()
            + '/' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate()
        ;

        try {
            const response: AxiosResponse<CalendarEntryResponse[]> = await this.apiV1Provider.get('/calendar/entries/' + dateRange);
            const calendarEntryResponses: CalendarEntryResponse[] = response.data;

            calendarEntryResponses.map(
                (calendarEntryResponse: CalendarEntryResponse): void => {
                    calendarEntries.push(CalendarEntry.createFromCalendarEntryResponse(calendarEntryResponse));
                }
            );

            return calendarEntries;
        } catch (error) {
            throw error;
        }
    }

    public async post(calendarEntryRequest: CalendarEntryRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/calendar/entries', calendarEntryRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async delete(calendarId: number): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.delete('/calendar/entries/' + calendarId);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async fetchById(id: number): Promise<CalendarEntry> {
        try {
            const response: AxiosResponse<CalendarEntryResponse> = await this.apiV1Provider.get('/calendar/entries/' + id.toString());
            const calendarEntryResponse: CalendarEntryResponse = response.data;

            return CalendarEntry.createFromCalendarEntryResponse(calendarEntryResponse);
        } catch (error) {
            throw error;
        }
    }
}

export default CalendarService;