import React, {useState} from "react";
import Picture from "../../../Entity/Picture/Picture";
import PictureView from "../../Component/PictureView";
import ButtonWithIcon from "../../../../Component/ButtonWithIcon";
import PictureService from "../../../Image/PictureService";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import Authentication from "../../../Entity/Authentication/Authentication";
import SpinnerOverParent from "../../../../Component/SpinnerOverParent";

interface RecipeFormImageCardProps {
    picture: Picture;
    onDelete: Function;
}

const RecipeFormImageCard = (props: RecipeFormImageCardProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    async function deletePicture(): Promise<void>
    {
        if (authentication === null || props.picture.id === null) {
            return;
        }

        const pictureService: PictureService = new PictureService(authentication);
        await pictureService.deleteFromRecipe(props.picture.recipeId, props.picture.id);
        props.onDelete(props.picture);
    }

    const callDeletePicture = (): void => {
        setShowSpinner(true);
        deletePicture();
    }

    return (
        <div className="col-xxl-2 col-md-3 col-12 card-shadow card mx-sm-3 mx-1 p-1">
            {showSpinner &&
                <SpinnerOverParent />
            }
            <div className="card-img-top">
                <PictureView picture={props.picture} className={"card-img card-img-category"}/>
            </div>
            <div className="card-footer">
                <ButtonWithIcon additionalClassName="delete-button fa-pull-right" icon="bi-trash3" iconType="Bootstrap" text="Löschen" onClick={callDeletePicture}/>
            </div>
        </div>
    );
}

export default RecipeFormImageCard;