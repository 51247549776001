import React, {PropsWithChildren} from "react";

interface FloatingBottomBarProps extends PropsWithChildren {

}

const FloatingBottomBar = (props: FloatingBottomBarProps): React.JSX.Element =>
{
    return (
        <div className="floating-bottom-bar">
            {props.children}
        </div>
    );
}

export default FloatingBottomBar;