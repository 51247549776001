import React, {useEffect, useState} from "react";
import Recipe from "../../../Entity/Recipe/Recipe";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import Authentication from "../../../Entity/Authentication/Authentication";
import PictureService from "../../../Image/PictureService";
import Picture from "../../../Entity/Picture/Picture";
import PictureView from "../../Component/PictureView";
import IconButton from "../../../../Component/IconButton";

interface RecipeCardProps {
    readonly recipe: Recipe;
    readonly onRemove: Function;
    readonly index: number;
    readonly onMoveUp?: Function;
    readonly onMoveDown?: Function;
}

const RecipeCard = (props: RecipeCardProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [pictureView, setPictureView] = useState<React.JSX.Element|null|undefined>(undefined);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();

    async function loadPicture(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const pictureService: PictureService = new PictureService(authentication);
        const pictures: Picture[] = await pictureService.getByRecipeId(props.recipe.id);

        if (pictures.length <= 0) {
            return;
        }

        const e = <PictureView picture={pictures[0]} className={"card-img card-img-category"} />;
        setPictureView(e);
    }

    useEffect((): void => {

        if (pictureView !== undefined) {
            setPictureView( undefined);
        }

        loadPicture();

    }, [props.recipe]);


    const removeRecipe = (): void => {
        props.onRemove(props.recipe);
    }

    const moveUp = (): void => {
        if (props.onMoveUp === undefined) {
            return;
        }

        props.onMoveUp(props.recipe);
    }

    const moveDown = (): void => {
        if (props.onMoveDown === undefined) {
            return;
        }

        props.onMoveDown(props.recipe);
    }


    return (
        <div className="board card-shadow card mx-sm-3 mx-1 p-1">
            <div className="card-header">
                {props.recipe.name}
            </div>
            <div className="card-img-top">
                {pictureView !== undefined && pictureView !== null &&
                    <>
                        {pictureView}
                    </>
                }
            </div>
            <div className="card-footer">
                <IconButton icon="bi-trash3" iconType="Bootstrap" additionalClassName="delete-button fa-pull-right" onClick={removeRecipe} />
                {props.onMoveUp !== undefined &&
                    <IconButton icon="arrow_left" iconType="Material" additionalClassName="fa-pull-left" onClick={moveUp} />
                }
                {props.onMoveDown !== undefined &&
                    <IconButton icon="arrow_right" iconType="Material" additionalClassName="fa-pull-left" onClick={moveDown} />
                }
            </div>
        </div>
    );
}

export default RecipeCard;