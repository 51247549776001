import React, {MouseEvent, MouseEventHandler} from "react";

interface BootstrapIconProps {
    readonly iconClassName: string;
    onClick?: MouseEventHandler;
}

const BootstrapIcon = (props: BootstrapIconProps): React.JSX.Element => {
    const onClick = (e: MouseEvent): void =>
    {
        if (props.onClick === undefined) {
            return;
        }

        props.onClick(e);
    }

    return (
        <>
            <i onClick={props.onClick} className={"bi " + props.iconClassName}></i>
        </>
    );
}

export default BootstrapIcon;