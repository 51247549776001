import React from "react";
import Picture from "../../Entity/Picture/Picture";
import CardSlider from "../../../Component/CardSlider";
import {Carousel} from "react-bootstrap";

interface PictureSliderProps {
    pictures: Picture[];
    imagesPerSlide: number;
    getImageTag: (picture: Picture) => React.JSX.Element;
}

const PictureSlider = (props: PictureSliderProps): React.JSX.Element => {

    function getImageCarouselItem(pictures: Picture[]): React.JSX.Element {
        return (
            <Carousel.Item>
                <div className="row">
                    {pictures.map((picture: Picture, index: number): React.JSX.Element => {
                        return (
                            <>{props.getImageTag(picture)}</>
                        );
                    })}
                </div>
            </Carousel.Item>
        );
    }

    function getImageCarouselItems(pictures: Picture[]): React.JSX.Element[] {
        let picturesToShow: Picture[] = [];
        const sliderImages: React.JSX.Element[] = [];

        props.pictures.forEach((picture: Picture, index: number): void => {
            if (index > 0 && index % props.imagesPerSlide === 0) {
                sliderImages.push(getImageCarouselItem(picturesToShow));
                picturesToShow = [picture];
            } else {
                picturesToShow.push(picture);
            }
        });

        if (picturesToShow.length > 0) {
            sliderImages.push(getImageCarouselItem(picturesToShow));
        }

        return sliderImages;
    }

    return (
        <CardSlider>
            {getImageCarouselItems(props.pictures)}
        </CardSlider>
    );
}

export default PictureSlider;