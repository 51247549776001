import React, {useEffect, useState} from 'react';
import Breadcrumb from '../Component/Breadcrumb';
import Board from '../Component/Board';
import FormData from '../../Entity/Form/FormData';
import Recipe from '../../Entity/Recipe/Recipe';
import FieldValidationDefinition from '../../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../../FormValidationHandler/RequiredValidationDefinition';
import FormValidationHandler from '../../FormValidationHandler/FormValidationHandler';
import NumericValidationDefinition from "../../FormValidationHandler/NumericValidationDefinition";
import RecipeForm from "./Component/RecipeForm";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import SpinnerOverlay from "../../../Component/SpinnerOverlay";
import Authentication from "../../Entity/Authentication/Authentication";
import AuthenticationService from "../../Authentication/AuthenticationService";
import RecipeService from "../../Recipe/RecipeService";
import FloatingBottomBar from "../../../Component/FloatingBottomBar";
import IconButton from "../../../Component/IconButton";
import DialogOverlay from "../../../Component/DialogOverlay";
import {Card} from "react-bootstrap";
import ButtonWithIcon from "../../../Component/ButtonWithIcon";
import AccountUserService from "../../AccountUser/AccountUserService";
import AccountUser from "../../Entity/AccountUser/AccountUser";
import DifficultyLevel from "../../Entity/Enums/DifficultyLevel";

interface RecipeEditorProps {
}

const RecipeEditor = (props: RecipeEditorProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const fieldValidationDefinitions: FieldValidationDefinition<Recipe>[] = [
        new RequiredValidationDefinition<Recipe>('name', 'Das Rezept benötigt eine Bezeichnung'),
        new RequiredValidationDefinition<Recipe>('categories', 'Das Rezept benötigt min eine Kategorie.'),
        new RequiredValidationDefinition<Recipe>('persons', 'Geben Sie bitte die Anzahl der Personen an.'),
        new NumericValidationDefinition<Recipe>('persons', 'Die Anzahl der Personen muss eine Zahl sein.'),
    ];
    const navigate: NavigateFunction = useNavigate();
    const formValidationHandler: FormValidationHandler<Recipe> = new FormValidationHandler<Recipe>(fieldValidationDefinitions);
    const {recipeId} = useParams<string>();
    const [recipe, setRecipe] = useState<Recipe|undefined>(undefined);
    const [formData, setFormData] = useState<FormData<Recipe>|undefined>(undefined);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [showDeleteDialog,setShowDeleteDialog] = useState<boolean>(false);

    async function checkAccountUserPermissions(): Promise<void>
    {
        if (authentication === null || recipe === undefined || recipe !== undefined && recipe.id === undefined) {
            return;
        }

        const accountUserService: AccountUserService = new AccountUserService(authentication);
        const accountUser: AccountUser = await accountUserService.profile();

        if (accountUser.id === recipe.accountUserId) {
            return;
        }

        if (accountUserService.isGranted('ROLE_ADMINISTRATOR', accountUser.roles)) {
            return;
        }

        navigate('/dashboard');
    }

    useEffect((): void => {
        if (authentication === null || recipe === undefined) {
            return;
        }
        checkAccountUserPermissions();

    }, [authentication, recipe]);

    useEffect((): void => {

        if (recipe !== undefined) {
            return;
        }

        if (recipeId === undefined) {
            const r: Recipe = new Recipe();
            r.difficultyLevel = DifficultyLevel.LOW;
            setRecipe(r);
            const data: FormData<Recipe> = {data: r, formValidationHandler: formValidationHandler};
            setFormData(data);
            return;
        }
        loadRecipe();

    }, [recipe]);

    async function loadRecipe(): Promise<void>
    {
        if(authentication === null || recipeId === undefined) {
            return;
        }
        const recipeService: RecipeService = new RecipeService(authentication);
        const recipe: Recipe = await recipeService.fetchById(parseInt(recipeId));

        setRecipe(recipe);
        const data: FormData<Recipe> = {data: recipe, formValidationHandler: formValidationHandler};
        setFormData(data);
    }

    function scrollUp(): void
    {
        const node: HTMLDivElement = document.getElementById('ingredientAutocomplete') as HTMLDivElement;
        node.scrollIntoView();
    }

    function submitForm(): void
    {
        const form: HTMLFormElement = document.querySelector('#recipeForm') as HTMLFormElement;
        const event: Event = new Event('submit', { bubbles: true, cancelable: true });

        if (form.reportValidity() === false) {
            return;
        }
        form.dispatchEvent(event);
    }

    function showDeleteRecipe(): void
    {
        setShowDeleteDialog(true);
    }

    async function deleteRecipe(): Promise<void>
    {
        if (authentication === null || recipeId === undefined) {
            return;
        }

        const recipeService: RecipeService = new RecipeService(authentication);
        await recipeService.delete(parseInt(recipeId));

        navigate('/rezepte');
    }

    function hideDeleteRecipe(): void
    {
        setShowDeleteDialog(false);
    }

    if (formData === undefined || recipe === undefined || authentication === null) {
        return (
            <SpinnerOverlay />
        );
    }

    return (
        <>
            {showDeleteDialog === true &&
                <DialogOverlay>
                    <Card>
                        <Card.Header>
                            Sie sind im Begriff dieses Rezept zu löschen
                        </Card.Header>
                        <Card.Body>
                            Es werde alle dazugehörigen Bilder gelöscht und es wird aus allen Gerichten entfernt!
                        </Card.Body>
                        <Card.Footer>
                            <ButtonWithIcon icon="bi-exclamation-diamond" text="Löschen" onClick={deleteRecipe} iconType="Bootstrap" additionalClassName="delete-button fa-pull-right" />
                            <ButtonWithIcon icon="bi-x-circle" text="Abbrechen" onClick={hideDeleteRecipe} iconType="Bootstrap" additionalClassName="fa-pull-right" />
                        </Card.Footer>
                    </Card>
                </DialogOverlay>
            }
            <Breadcrumb>
                {recipeId === undefined &&
                    <>Anlegen</>
                }
                {recipeId !== undefined &&
                <>Bearbeiten</>
                }
            </Breadcrumb>
            <Board>
                <RecipeForm className="pb-lg-4 pb-5" setFormData={setFormData} formValidationHandler={formValidationHandler} formData={formData} />
                <FloatingBottomBar>
                    <IconButton icon="fa-floppy-disk" iconType="FontAwsome" onClick={submitForm} />
                    {recipeId !== undefined &&
                        <IconButton icon="bi-trash3" iconType="Bootstrap" additionalClassName="delete-button ms-2" onClick={showDeleteRecipe} />
                    }
                </FloatingBottomBar>
            </Board>
        </>
    );
}

export default RecipeEditor;