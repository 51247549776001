import Icon from "../../../../Component/Icon";
import DishDropZone from "../../Component/Dish/DishDropZone";
import React, {useEffect, useState} from "react";
import CalendarEntry from "../../../Entity/Calendar/CalendarEntry";

interface WeekTableProps {
    dateTimeStart: Date;
    calendarEntries: CalendarEntry[]|null;
}

type IconType = 'Bootstrap' | 'FontAwsome' | 'Material';

const WeekTable = (props: WeekTableProps): React.JSX.Element => {
    const weekDays: number[] = [0, 1, 2, 3, 4, 5, 6];
    const dayHours: number[] = [7, 10, 12, 15, 20];
    const icons: {iconType: IconType, icon: string}[] = [
        {iconType: 'Bootstrap', icon: 'bi-cup'},
        {iconType: 'Bootstrap', icon: 'bi-egg-fried'},
        {iconType: 'FontAwsome', icon: 'fa-pizza-slice'},
        {iconType: 'Bootstrap', icon: 'bi-cake2'},
        {iconType: 'Material', icon: 'local_dining'},
    ];
    const dateTime: Date = new Date(props.dateTimeStart);

    let week: Date[] = [];
    for(let i: number = 0; i < 7; i++) {
        week.push(new Date(dateTime));
        dateTime.setDate(dateTime.getDate() + 1);
    }

    function fetchCalendarEntry(dateTime: Date): CalendarEntry|null
    {
        if (props.calendarEntries === null || props.calendarEntries.length <= 0) {
            return null;
        }

        for (let i: number = 0; i < props.calendarEntries.length; i++) {
            if (props.calendarEntries[i].dateTime.getTime() === dateTime.getTime()) {
                return props.calendarEntries[i];
            }
        }

        return null;
    }

    return (
        <>
            <table className="table table-striped table-bordered h-100 w-100">
                <thead>
                <tr>
                    <th className="dish-table-first-column">Zeit</th>
                    <th className="dish-table-column">Mo.</th>
                    <th className="dish-table-column">Di.</th>
                    <th className="dish-table-column">Mi.</th>
                    <th className="dish-table-column">Do.</th>
                    <th className="dish-table-column">Fr.</th>
                    <th className="dish-table-column">Sa.</th>
                    <th className="dish-table-column">So.</th>
                </tr>
                </thead>
                <tbody>
                {dayHours.map(
                    function (hour: number, hourIndex: number): React.JSX.Element {
                        return (
                            <tr>
                                <td><Icon icon={icons[hourIndex].icon} iconType={icons[hourIndex].iconType} className="secondary-icon"/></td>
                                {weekDays.map(
                                    function (weekDay: number, weekDayIndex: number): React.JSX.Element {
                                        const dateTime: Date = new Date(week[weekDayIndex].setHours(hour));
                                        return (
                                            <td className="dish-table-column"><DishDropZone dateTime={dateTime} calendarEntry={fetchCalendarEntry(dateTime)}/></td>
                                        );
                                    }
                                )}
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>
        </>
    );
}

export default WeekTable;