import InputField from "../../../../Component/Form/Field/InputField";
import FormValidationHandler from "../../../FormValidationHandler/FormValidationHandler";
import React from "react";
import FormDataObject from "../../../Entity/Form/FormData";
import AccountUser from "../../../Entity/AccountUser/AccountUser";

interface AccountUserPasswordResetFormProps {
    readonly formData: FormDataObject<AccountUser>;
    readonly formValidationHandler: FormValidationHandler<AccountUser>;
    readonly setFormData: Function;
    readonly uuid?: string;
}

const AccountUserPasswordResetForm = (props: AccountUserPasswordResetFormProps): React.JSX.Element => {
    const accountUser: AccountUser = props.formData.data;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (accountUser as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: accountUser});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            {props.uuid === undefined &&
                <div className="mb-4">
                    <InputField
                        name="email"
                        label="Email"
                        type="email"
                        value={(accountUser.email !== null) ? accountUser.email : ''}
                        onChange={handleChange}
                        required={true}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'email')}
                    />
                </div>
            }
            {props.uuid !== undefined &&
                <>
                    <div className="row">
                        <div className="col mb-4">
                            <InputField
                                name="password"
                                label="Kennwort"
                                type="password"
                                value={(accountUser.password !== null) ? accountUser.password : ''}
                                onChange={handleChange}
                                required={accountUser.id === null}
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'password')}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-4">
                                <InputField
                                    name="passwordRetype"
                                    label="Kennwort wiederholen"
                                    type="password"
                                    value={(accountUser.passwordRetype !== null) ? accountUser.passwordRetype : ''}
                                    onChange={handleChange}
                                    required={accountUser.id === null}
                                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'passwordRetype')}
                                />
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default AccountUserPasswordResetForm;