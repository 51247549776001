import FileResponse from "./FileResponse";

class File {
    private _id: number|null;
    private _uuid: string;
    private _fileName: string;
    private _fileExtension: string|null;
    private _fileSize: number;
    private _mimeType: string;
    private _pathToFile: string;

    static createFromFileResponse(fileResponse: FileResponse): File
    {
        const file: File = new File();

        file._id = fileResponse.id;
        file._uuid = fileResponse.uuid;
        file._fileName = fileResponse.fileName;
        file._fileExtension = fileResponse.fileExtension;
        file._fileSize = fileResponse.fileSize;
        file._mimeType = fileResponse.mimeType;
        file._pathToFile = fileResponse.pathToFile;

        return file;
    }

    get id(): number | null {
        return this._id;
    }

    set id(value: number | null) {
        this._id = value;
    }

    get uuid(): string {
        return this._uuid;
    }

    set uuid(value: string) {
        this._uuid = value;
    }

    get fileName(): string {
        return this._fileName;
    }

    set fileName(value: string) {
        this._fileName = value;
    }

    get fileExtension(): string | null {
        return this._fileExtension;
    }

    set fileExtension(value: string | null) {
        this._fileExtension = value;
    }

    get fileSize(): number {
        return this._fileSize;
    }

    set fileSize(value: number) {
        this._fileSize = value;
    }

    get mimeType(): string {
        return this._mimeType;
    }

    set mimeType(value: string) {
        this._mimeType = value;
    }

    get pathToFile(): string {
        return this._pathToFile;
    }

    set pathToFile(value: string) {
        this._pathToFile = value;
    }
}

export default File;