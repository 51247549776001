import ApiV1Provider from '../../Api/ApiV1Provider';
import Authentication from '../Entity/Authentication/Authentication';
import Ingredient from '../Entity/Recipe/Ingredient';
import IngredientResponse from '../Entity/Recipe/IngredientResponse';
import {AxiosResponse} from 'axios';
import IngredientRequest from "../Entity/Recipe/IngredientRequest";

class IngredientService {
    private readonly apiV1Provider: ApiV1Provider

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
    }

    public async fetchAll(): Promise<Ingredient[]> {
        const ingredients:Ingredient[] = [];

        try {
            const response: AxiosResponse<IngredientResponse[]> = await this.apiV1Provider.get('/ingredients');
            const ingredientResponses: IngredientResponse[] = response.data;
            ingredientResponses.forEach((ingredientResponse: IngredientResponse): void => {
                ingredients.push(Ingredient.createFromIngredientResponse(ingredientResponse));
            });
            return ingredients;
        } catch (error) {
            throw error;
        }
    }

    public async fetchByRecipeId(recipeId: number): Promise<Ingredient[]> {
        const ingredients:Ingredient[] = [];

        try {
            const response: AxiosResponse<IngredientResponse[]> = await this.apiV1Provider.get('/recipes/' + recipeId + '/ingredients');
            const ingredientResponses: IngredientResponse[] = response.data;
            ingredientResponses.forEach((ingredientResponse: IngredientResponse): void => {
                ingredients.push(Ingredient.createFromIngredientResponse(ingredientResponse));
            });
            return ingredients;
        } catch (error) {
            throw error;
        }
    }

    public async fetchByComponentId(recipeId: number, componentId: number): Promise<Ingredient[]> {
        const ingredients:Ingredient[] = [];

        try {
            const response: AxiosResponse<IngredientResponse[]> = await this.apiV1Provider.get('/recipes/' + recipeId + '/components/' + componentId + '/ingredients');
            const ingredientResponses: IngredientResponse[] = response.data;
            ingredientResponses.forEach((ingredientResponse: IngredientResponse): void => {
                ingredients.push(Ingredient.createFromIngredientResponse(ingredientResponse));
            });
            return ingredients;
        } catch (error) {
            throw error;
        }
    }

    public async post(recipeId: number, componentId: number, ingredientRequest: IngredientRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/recipes/' + recipeId + '/components/' + componentId + '/ingredients', ingredientRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async put(recipeId: number, componentId: number, ingredientRequest: IngredientRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.put('/recipes/' + recipeId + '/components/' + componentId + '/ingredients/' + ingredientRequest.id, ingredientRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async delete(recipeId: number, componentId: number, ingredientid: number): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.delete('/recipes/' + recipeId + '/components/' + componentId + '/ingredients/' + ingredientid);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default IngredientService;