import React, {useEffect, useState} from "react";
import DishDropElement from "../../../Entity/Dish/DragDrop/DishDropElement";
import IconButton from "../../../../Component/IconButton";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import Authentication from "../../../Entity/Authentication/Authentication";
import CalendarService from "../../../Calendar/CalendarService";
import PictureService from "../../../Image/PictureService";
import CalendarEntryRequest from "../../../Entity/Calendar/CalendarEntryRequest";
import {AxiosResponse} from "axios";
import Picture from "../../../Entity/Picture/Picture";
import CalendarEntry from "../../../Entity/Calendar/CalendarEntry";

interface DishClickZoneProps {
    label: string;
    dateTime: Date;
    calendarEntry: CalendarEntry|null;
    item: DishDropElement | null;
    setDishDropElement: Function;
}

const DishClickZone = (props: DishClickZoneProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [calendarService, setCalendarService] = useState<CalendarService|null>(null);
    const [pictureService, setPictureService] = useState<PictureService|null>(null);
    const [droppedItem, setDroppedItem] = useState<DishDropElement | null>(null);
    const [calendarEntry, setCalendarEntry] = useState<CalendarEntry|null>(null);

    useEffect((): void => {
        setCalendarEntry(props.calendarEntry);

        if (props.calendarEntry === null || props.calendarEntry.id === null) {
            setDroppedItem(null);
            return;
        }

        setDroppedItem({
            id: props.calendarEntry.id,
            name: props.calendarEntry.dish.name,
            pictureUrl: null
        });

    }, [props.calendarEntry]);

    useEffect((): void => {
        if (props.calendarEntry !== null && props.calendarEntry.id !== null) {
            return;
        }

        setDroppedItem(null);

    }, [props.dateTime]);


    useEffect((): void => {
        if (calendarService !== null || authentication === null) {
            return;
        }

        setCalendarService(new CalendarService(authentication));
        setPictureService(new PictureService(authentication));
    }, [authentication, calendarService, pictureService]);

    useEffect((): void => {
        if (droppedItem === null || droppedItem.pictureUrl !== null) {
            return;
        }

        loadCalendarEntryPicture();

    }, [droppedItem]);

    async function saveDroppedItem(item: DishDropElement): Promise<void>
    {
        if (calendarService === null) {
            return;
        }

        const calendarEntryRequest: CalendarEntryRequest = {
            dateTime: props.dateTime.toISOString().slice(0, 10) +
                ' ' + props.dateTime.getHours() +
                ':' + props.dateTime.getMinutes(),
            dishId: item.id
        }

        const response: AxiosResponse = await calendarService.post(calendarEntryRequest);
        setDroppedItem( {
            id: parseInt(response.headers['x-id']),
            name: item.name,
            pictureUrl: item.pictureUrl
        });
    }

    async function loadCalendarEntryPicture(): Promise<void>
    {
        if (pictureService === null || droppedItem === null || props.calendarEntry === null) {
            return;
        }

        for (let i: number = 0; i < props.calendarEntry.dish.recipes.length; i++) {
            let pictures: Picture[] = await pictureService.getByRecipeId(props.calendarEntry.dish.recipes[i].id);

            if (pictures.length <= 0) {
                continue;
            }

            let pictureUrl: string | undefined = await pictureService.getScaledImageUrl(pictures[0],100,100);

            if (pictureUrl === undefined) {
                continue;
            }

            setDroppedItem({
                id: droppedItem.id,
                name: droppedItem.name,
                pictureUrl: pictureUrl,
            });

            break;
        }
    }


    const setDropedItemFromProps = (): void => {
        if (props.item === null) {
            return;
        }
        setDroppedItem(props.item);

        saveDroppedItem(props.item);

        props.setDishDropElement(null);
    }

    const removeDroppedItem = (): void => {
        if (calendarService === null || droppedItem === null ) {
            return;
        }

        calendarService.delete(droppedItem.id);

        setDroppedItem(null);
    }

    return (
        <div className="w-100 h-100 backgroundColorPrimary40" onClick={setDropedItemFromProps}>
            {droppedItem !== null &&
                <div className="card-overlay-container">
                    <div className="card card-shadow">
                        <div className="card-header h6 nowrap-text p-0 ps-1 dragable-dish-header">
                            {props.label}
                        </div>
                        {droppedItem.pictureUrl !== null &&
                            <img src={droppedItem.pictureUrl}
                                 className="picture-view card-img fit-cover-w100-h80 not-clickable-picture m-0 p-0"/>
                        }
                        {props.label !== undefined &&
                            <div className="card-body dragable-dish-bodyps-1 droped-card-body-text nowrap-text">
                                {droppedItem.name}
                            </div>
                        }
                    </div>

                    <div className="card-overlay">
                        <IconButton icon="bi-trash3" iconType="Bootstrap" additionalClassName="delete-button ms-2" onClick={removeDroppedItem} />
                    </div>
                </div>
            }
            {droppedItem === null &&
                <div className="dish-drop-zone-label">
                    { props.label !== undefined &&
                        <div className="m-3">{props.label} {props.dateTime.getDate().toString().padStart(2, '0')}.{(props.dateTime.getMonth() + 1).toString().padStart(2, '0')}</div>
                    }
                </div>
            }
        </div>
    );
}

export default DishClickZone;