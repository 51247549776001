import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface PublicRouteProps {
    isAuthenticated: Function;
}

const PublicRoute = (props: PublicRouteProps): React.JSX.Element => {
    if (props.isAuthenticated() === true) {
        return <Navigate to="/dashboard" replace />;
    }

    return <Outlet />;
};

export default PublicRoute;
