import Breadcrumb from "../Component/Breadcrumb";
import React, {useEffect, useState} from "react";
import Board from "../Component/Board";
import DishesContainer from "../Component/Dish/DishesContainer";
import MobileWeekTable from "./WeekPlaner/MobileWeekTable";
import WeekTable from "./WeekPlaner/WeekTable";
import DishDropElement from "../../Entity/Dish/DragDrop/DishDropElement";
import CalendarService from "../../Calendar/CalendarService";
import ButtonWithIcon from "../../../Component/ButtonWithIcon";
import AuthenticationService from "../../Authentication/AuthenticationService";
import Authentication from "../../Entity/Authentication/Authentication";
import CalendarEntry from "../../Entity/Calendar/CalendarEntry";

interface WeekPlanerViewProps {

}

const WeekPlanerView = (props: WeekPlanerViewProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [isMobileView, setIsMobileView] = useState<boolean|null>(null);
    const [dishDropElement, setDishDropElement] = useState<DishDropElement|null>(null);
    const [calendarService, setCalendarService] = useState<CalendarService|undefined>(undefined);
    const [calendarWeek, setCalendarWeek] = useState<number|undefined>(undefined);
    const [dateMonday, setDateMonday] = useState<Date|null>(null);
    const [dateSunday, setDateSunday] = useState<Date|null>(null);
    const [year, setYear] = useState<number>((new Date()).getFullYear());
    const [calendarEntries, setCalendarEntries] = useState<CalendarEntry[]|null|undefined>(undefined);


    useEffect((): void => {
        if (isMobileView !== null) {
            return;
        }

        if (window.innerWidth <= 1205) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }

    }, [isMobileView]);

    useEffect((): void => {
        if (calendarService !== undefined || authentication === null) {
            return;
        }
        setCalendarService(new CalendarService(authentication));

    }, [calendarService, calendarWeek]);

    useEffect((): void => {
        if (calendarService === undefined || calendarWeek !== undefined) {
            return;
        }

        setCalendarWeek(calendarService.getCurrentWeekNumber());

    }, [calendarService, calendarWeek]);

    useEffect((): void => {
        if (calendarWeek === undefined || calendarService === undefined) {
            return;
        }

        setDateMonday(null);
        setDateSunday(null);

        const startAndEndDate: {startDate: Date, endDate: Date} = calendarService.getStartDateAndEndDateFromWeekNumberAndYear(
            calendarWeek,
            year
        );

        setDateMonday(startAndEndDate.startDate);
        setDateSunday(startAndEndDate.endDate);

    }, [calendarWeek]);

    useEffect((): void => {
        if (dateMonday === null || dateSunday === null) {
            return;
        }

        loadCalendarEntriesInDateRange(dateMonday, dateSunday);

    }, [dateMonday, dateSunday]);

    async function loadCalendarEntriesInDateRange(dateFrom: Date, dateTo: Date): Promise<void>
    {
        if (calendarService === undefined) {
            return;
        }

        const results: CalendarEntry[] = await calendarService.getInDateRange(dateFrom,dateTo);
        setCalendarEntries(results);
    }

    const prevWeek = (): void => {
        if (calendarService === undefined || calendarWeek === undefined) {
            return;
        }

        const newCalendarWeek: number = calendarWeek - 1;

        if (calendarService.isWeekNumberValid(newCalendarWeek) === false) {
            setCalendarWeek(53);
            setYear((year - 1));
            return;
        }

        setCalendarWeek(newCalendarWeek);
    }

    const nextWeek = (): void => {
        if (calendarService === undefined || calendarWeek === undefined) {
            return;
        }

        const newCalendarWeek: number = calendarWeek + 1;

        if (calendarService.isWeekNumberValid(newCalendarWeek) === false) {
            setCalendarWeek(1);
            setYear((year + 1));
            return;
        }

        setCalendarWeek(newCalendarWeek);
    }

    return (
        <>
            <Breadcrumb>Wochplaner</Breadcrumb>
            <Board>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <div className="row">
                            <div className="col-auto">
                                <ButtonWithIcon icon="fa-chevron-left" text="Zurück" onClick={prevWeek} iconType="FontAwsome" additionalClassName="min-xl-width-120" />
                            </div>
                            <div className="col">
                                <p>Kalenderwoche {calendarWeek} - {year}</p>
                            </div>
                            <div className="col-auto">
                                <ButtonWithIcon icon="fa-chevron-right" text="Vor" textAlign="right" onClick={nextWeek} iconType="FontAwsome" additionalClassName="min-xl-width-120" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-12 col-xxl-7 order-1 order-xxl-0">
                        <div className="table-responsive">
                            {isMobileView === true && dateMonday !== null && dateSunday !== null && dateSunday !== null && calendarEntries !== undefined &&
                                <MobileWeekTable item={dishDropElement} setDishDropElement={setDishDropElement}  dateTimeStart={new Date(dateMonday)} calendarEntries={calendarEntries}/>
                            }
                            {isMobileView === false && dateMonday !== null && dateSunday !== null && calendarEntries !== undefined &&
                                <WeekTable dateTimeStart={new Date(dateMonday)} calendarEntries={calendarEntries} />
                            }
                        </div>
                    </div>
                    <div className="col-12 col-xxl-5 order-0 order-xxl-1">
                        { isMobileView !== null &&
                            <DishesContainer mobileView={isMobileView} currentItem={dishDropElement} setDishDropElement={setDishDropElement} />
                        }
                    </div>
                </div>
            </Board>
        </>
    );
}

export default WeekPlanerView;