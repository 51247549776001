import Board from '../Component/Board';
import AuthenticationService from '../../Authentication/AuthenticationService';
import RecipeCategory from '../../Entity/RecipeCategory/RecipeCategory';
import Authentication from '../../Entity/Authentication/Authentication';
import SpinnerOverlay from '../../../Component/SpinnerOverlay';
import RecipeCategoryService from '../../RecipeCategory/RecipeCategoryService';
import Breadcrumb from '../Component/Breadcrumb';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Recipe from "../../Entity/Recipe/Recipe";
import RecipeService from "../../Recipe/RecipeService";
import RecipeCard from "./Component/RecipeCard";
import AccountUser from "../../Entity/AccountUser/AccountUser";
import AccountUserService from "../../AccountUser/AccountUserService";

interface RecipeCategoryOverviewProps {

}

const RecipeCategoryOverview = (props: RecipeCategoryOverviewProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recipeCategory, setRecipeCategory] = useState<RecipeCategory|undefined>(undefined);
    const [reciepes, setRecipes] = useState<Recipe[]|undefined>(undefined);
    const [accountUser, setAccountUser] = useState<AccountUser|null|undefined>(undefined);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const {categoryId} = useParams<string>();

    async function loadAccountUser(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const accountUserService: AccountUserService = new AccountUserService(authentication);
        setAccountUser(await accountUserService.profile());
    }

    useEffect((): void => {
        if (accountUser !== undefined || authentication === null) {
            return;
        }
        loadAccountUser();

    }, [accountUser, authentication]);

    async function loadReciepes(): Promise<void>
    {
        if (authentication === null || recipeCategory === undefined) {
            return;
        }
        setIsLoading(true);

        const recipeService: RecipeService = new RecipeService(authentication);

        setRecipes(await recipeService.fetchByCategory(recipeCategory.recipeCategory));

        setIsLoading(false);
    }

    async function fetchRecipeCategory(): Promise<void>
    {
        if (authentication === null || categoryId === undefined) {
            return;
        }
        setIsLoading(true);
        const id: number = parseInt(categoryId);

        const recipeCategoryService: RecipeCategoryService = new RecipeCategoryService(authentication);
        setRecipeCategory(await recipeCategoryService.fetchById(id));
        setIsLoading(false);
    }

    useEffect((): void => {
            if (recipeCategory !== undefined || categoryId === undefined) {
                return;
            }
            fetchRecipeCategory();
        },
        [recipeCategory]
    );

    useEffect((): void => {
            if (recipeCategory === undefined || categoryId === undefined || reciepes !== undefined) {
                return;
            }
            loadReciepes();
        },
        [reciepes, recipeCategory]
    );

    if (authentication === null || recipeCategory === undefined || accountUser === null) {
        return (<></>);
    }

    if (accountUser === undefined) {
        return (
            <SpinnerOverlay />
        );
    }

    const accountUserService: AccountUserService = new AccountUserService(authentication);

    return (
        <>
            {isLoading === true &&
                <SpinnerOverlay />
            }
            <Breadcrumb>
                {recipeCategory.name}
            </Breadcrumb>
            <Board>
                <div className="row">
                    <div className="col-12">
                        <h1>{recipeCategory.name}</h1>
                    </div>
                </div>
                {((): React.JSX.Element => {
                    if (reciepes === undefined) {
                        return (
                            <div className="col-12">
                                Keine Rezepte vorhanden.
                            </div>
                        );
                    } else {
                        return (
                            <div className="row">
                                {reciepes.map((recipe: Recipe): React.JSX.Element => (
                                    <div className="col-xxl-3 col-xl-4 col-12 pt-3">
                                        <RecipeCard
                                            accountUser={accountUser}
                                            accountUserService={accountUserService}
                                            recipe={recipe}
                                        />
                                    </div>
                                ))}
                            </div>
                        );
                    }
                })()}
            </Board>
        </>
);
}

export default RecipeCategoryOverview;