import React from "react";
import AccordionItem from "../../../../Component/AccordionItem";
import DishClickZone from "../../Component/Dish/DishClickZone";
import DishDropElement from "../../../Entity/Dish/DragDrop/DishDropElement";
import CalendarEntry from "../../../Entity/Calendar/CalendarEntry";

interface MobileWeekTableProps {
    item: DishDropElement | null;
    setDishDropElement: Function;
    dateTimeStart: Date;
    calendarEntries: CalendarEntry[]|null;
}

const MobileWeekTable = (props: MobileWeekTableProps): React.JSX.Element => {
    const weekDays: number[] = [0, 1, 2, 3, 4, 5, 6];
    const dayHours: number[] = [7, 10, 12, 15, 20];
    const dateTime: Date = new Date(props.dateTimeStart);
    const dayHoursLabel: {label: string, id: string}[] = [
        {label: 'Frühstück', id: 'Breakfast'},
        {label: 'Brunch', id: 'Brunch'},
        {label: 'Mittag', id: 'Lunch'},
        {label: 'Kaffee', id: 'Coffee'},
        {label: 'Abend', id: 'Dinner'},
    ];

    const weekDaysLabel: string[] = [
        'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.', 'So.'
    ];

    let week: Date[] = [];
    for(let i: number = 0; i < 7; i++) {
        week.push(new Date(dateTime));
        dateTime.setDate(dateTime.getDate() + 1);
    }

    function fetchCalendarEntry(dateTime: Date): CalendarEntry|null
    {
        if (props.calendarEntries === null || props.calendarEntries.length <= 0) {
            return null;
        }

        for (let i: number = 0; i < props.calendarEntries.length; i++) {
            if (props.calendarEntries[i].dateTime.getTime() === dateTime.getTime()) {
                return props.calendarEntries[i];
            }
        }

        return null;
    }

    return (
        <div className="container mt-3">
            <div className="accordion" id="accordionMobileWeekTable">
                {dayHours.map(
                    function (hour: number, hourIndex: number): React.JSX.Element {
                        return (
                            <AccordionItem head={dayHoursLabel[hourIndex].label} id={dayHoursLabel[hourIndex].id} parentId="accordionMobileWeekTable">
                                <div className="row center-row">
                                {weekDays.map(
                                    function (weekDay: number, weekDayIndex: number): React.JSX.Element {
                                        const dateTime: Date = new Date(week[weekDayIndex].setHours(hour));
                                        return (
                                            <div className="col-3 col-w-100px col-md-w-140px col-h-90px p-0 ms-0 mb-1">
                                                <DishClickZone label={weekDaysLabel[weekDayIndex]} item={props.item} setDishDropElement={props.setDishDropElement} calendarEntry={fetchCalendarEntry(dateTime)} dateTime={dateTime}/>
                                            </div>
                                        );
                                    }
                                )}
                                </div>
                            </AccordionItem>
                        );
                    })}
            </div>
        </div>
    );
}

export default MobileWeekTable;