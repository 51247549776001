import DishRecipe from "./DishRecipe";
import DishRecipeResponse from "./DishRecipeResponse";
import DishResponse from "./DishResponse";

class Dish {
    private _id: number|null;

    private _name: string;

    private _recipes: DishRecipe[];

    static createFromDishResponse(dishResponse: DishResponse): Dish
    {
        const dish: Dish = new Dish();

        dish._id = dishResponse.id;
        dish._name = dishResponse.name;

        dishResponse.recipes.map(
            function(dishRecipeRequest: DishRecipeResponse): void {
                dish._recipes.push(DishRecipe.createFromDishRecipeResponse(dishRecipeRequest));
            },
            dishResponse.recipes
        );

        return dish;
    }

    constructor() {
        this._recipes = [];
    }

    get id(): number | null {
        return this._id;
    }

    set id(value: number | null) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get recipes(): DishRecipe[] {
        return this._recipes;
    }

    set recipes(value: DishRecipe[]) {
        this._recipes = value;
    }
}

export default Dish;