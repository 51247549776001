import Unit from "../Entity/Enums/Unit";
import SelectOption from "../Entity/Form/SelectOption";

class UnitService {
    public getNameFromUnit(unit: Unit): string
    {
        if (unit === Unit.GRAM) {
            return 'Gramm';
        } else if (unit === Unit.KILOGRAM) {
            return 'Kilogramm';
        } else if (unit === Unit.MILLIGRAM) {
            return 'Milligramm';
        } else if (unit === Unit.OUNCE) {
            return 'Unze';
        } else if (unit === Unit.POUND) {
            return 'Pfund';
        } else if (unit === Unit.LITRE) {
            return 'Liter';
        } else if (unit === Unit.MILLILITRE) {
            return 'Milliliter';
        } else if (unit === Unit.CUP) {
            return 'Tasse';
        } else if (unit === Unit.TEASPOON) {
            return 'Teelöffel';
        } else if (unit === Unit.TABLESPOON) {
            return 'Esslöffel';
        } else if (unit === Unit.PIECE) {
            return 'Stück';
        } else if (unit === Unit.PINCH) {
            return 'Priese';
        } else if (unit === Unit.TINS) {
            return 'Dose';
        }

        throw new Error('Unknown unit ' + unit);
    }

    public getUnitsAsOptions(): SelectOption<Unit>[]
    {
        return [
            {label: this.getNameFromUnit(Unit.GRAM), value: Unit.GRAM},
            {label: this.getNameFromUnit(Unit.KILOGRAM), value: Unit.KILOGRAM},
            {label: this.getNameFromUnit(Unit.MILLIGRAM), value: Unit.MILLIGRAM},
            {label: this.getNameFromUnit(Unit.OUNCE), value: Unit.OUNCE},
            {label: this.getNameFromUnit(Unit.POUND), value: Unit.POUND},
            {label: this.getNameFromUnit(Unit.LITRE), value: Unit.LITRE},
            {label: this.getNameFromUnit(Unit.MILLILITRE), value: Unit.MILLILITRE},
            {label: this.getNameFromUnit(Unit.CUP), value: Unit.CUP},
            {label: this.getNameFromUnit(Unit.TEASPOON), value: Unit.TEASPOON},
            {label: this.getNameFromUnit(Unit.TABLESPOON), value: Unit.TABLESPOON},
            {label: this.getNameFromUnit(Unit.PIECE), value: Unit.PIECE},
            {label: this.getNameFromUnit(Unit.PINCH), value: Unit.PINCH},
            {label: this.getNameFromUnit(Unit.TINS), value: Unit.TINS},
        ];
    }
}

export default UnitService;