import IngredientResponse from "./IngredientResponse";
import Unit from "../Enums/Unit";

class Ingredient {
    private _id: number;
    private _name: string;
    private _description: string|null;
    private _unit: Unit;
    private _quantity: number;

    static createFromIngredientResponse(ingredientResponse: IngredientResponse): Ingredient {
        if (ingredientResponse.unit in Unit === false) {
            throw new Error('Unknown ingredient unit');
        }

        const ingredient: Ingredient = new Ingredient();
        ingredient._id = ingredientResponse.id;
        ingredient._name = ingredientResponse.name;
        ingredient._description = ingredientResponse.description;
        ingredient._unit = ingredientResponse.unit as Unit;
        ingredient._quantity = ingredientResponse.quantity;

        return ingredient;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string|null {
        return this._description;
    }

    set description(value: string|null) {
        this._description = value;
    }

    get unit(): Unit {
        return this._unit;
    }

    set unit(value: Unit) {
        this._unit = value;
    }

    get quantity(): number {
        return this._quantity;
    }

    set quantity(value: number) {
        this._quantity = value;
    }
}

export default Ingredient;