import React, {MouseEvent, MouseEventHandler} from "react";

interface FontAwesomeIconProps {
    iconClassName: string;
    onClick?: MouseEventHandler;
}

const FontAwesomeIcon = (props: FontAwesomeIconProps): React.JSX.Element => {
    const onClick = (e: MouseEvent): void =>
    {
        if (props.onClick === undefined) {
            return;
        }

        props.onClick(e);
    }

    return (
        <>
            <i onClick={props.onClick} className={"fa-solid " + props.iconClassName}></i>
        </>
    );
}

export default FontAwesomeIcon;