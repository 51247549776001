import React, {useEffect, useState} from 'react';
import Board from "../Component/Board";
import Category from "./Component/Category";
import RecipeCategory from '../../Entity/RecipeCategory/RecipeCategory';
import AuthenticationService from '../../Authentication/AuthenticationService';
import Authentication from '../../Entity/Authentication/Authentication';
import RecipeCategoryService from '../../RecipeCategory/RecipeCategoryService';
import SpinnerOverlay from '../../../Component/SpinnerOverlay';
import Breadcrumb from '../Component/Breadcrumb';
import FloatingIcon from '../../../Component/FloatingIcon';
import {NavigateFunction, useNavigate} from 'react-router-dom';

interface RecipeCategoriesOverviewProps {

}

const RecipeCategoriesOverview = (props: RecipeCategoriesOverviewProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recipeCategories, setRecipeCategories] = useState<RecipeCategory[]|undefined>(undefined);
    const navigate: NavigateFunction = useNavigate();

    const authentication: Authentication|null = authenticationService.fetchAuthentication();

    async function fetchAllRecipeCategories(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        setIsLoading(true);

        const recipeCategoryService: RecipeCategoryService = new RecipeCategoryService(authentication);
        setRecipeCategories(await recipeCategoryService.fetchAll());
        setIsLoading(false);
    }

    useEffect((): void => {
            if (recipeCategories !== undefined) {
                return;
            }
            fetchAllRecipeCategories();
        },
        [recipeCategories]
    );

    if (authentication === null) {
        return (<></>);
    }

    function openEditor(): void {
        navigate('/rezepte/editor');
    }

    return (
        <>
            {isLoading === true &&
                <SpinnerOverlay />
            }
            <Breadcrumb />
            <Board>
                <div className="row">
                    {recipeCategories !== undefined &&
                        <>
                            {recipeCategories.map((recipeCategory: RecipeCategory): React.JSX.Element => (
                                <Category recipeCategory={recipeCategory}/>
                            ))}
                        </>
                    }
                </div>
                <FloatingIcon onClick={openEditor} icon="fa-plus" iconType="FontAwsome" align="right" />
            </Board>
        </>
    );
}

export default RecipeCategoriesOverview;