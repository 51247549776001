import ApiV1Provider from '../../Api/ApiV1Provider';
import Authentication from '../Entity/Authentication/Authentication';
import RecipeCategory from '../Entity/RecipeCategory/RecipeCategory';
import RecipeCategoryResponse from '../Entity/RecipeCategory/RecipeCategoryResponse';
import {AxiosResponse} from "axios";

class RecipeCategoryService {
    private readonly apiV1Provider: ApiV1Provider

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
    }

    public async fetchAll(): Promise<RecipeCategory[]> {
        const recipeCategories:RecipeCategory[] = [];

        try {
            const response: AxiosResponse<RecipeCategoryResponse[]> = await this.apiV1Provider.get('/recipes/categories');
            const recipeCategoryResponses: RecipeCategoryResponse[] = response.data;
            recipeCategoryResponses.forEach((recipeCategoryResponse: RecipeCategoryResponse): void => {
                recipeCategories.push(RecipeCategory.createFromRecipeCategoryResponse(recipeCategoryResponse));
            });
            return recipeCategories;
        } catch (error) {
            throw error;
        }
    }

    public async fetchById(id: number): Promise<RecipeCategory> {
        try {
            const response: AxiosResponse<RecipeCategoryResponse> = await this.apiV1Provider.get('/recipes/categories/' + id);
            const recipeCategoryResponse: RecipeCategoryResponse = response.data;
            return RecipeCategory.createFromRecipeCategoryResponse(recipeCategoryResponse);
        } catch (error) {
            throw error;
        }
    }
}

export default RecipeCategoryService;