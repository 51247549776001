import React from 'react';

interface HeaderProps extends React.PropsWithChildren {
}

function Header(props: HeaderProps) {

    return (
        <header className="header header-sticky">
            {props.children}
        </header>
    );
}

export default Header;
