import React, {useEffect, useState} from "react";
import Dish from "../../../Entity/Dish/Dish";
import Picture from "../../../Entity/Picture/Picture";
import PictureView from "../PictureView";
import DishRecipe from "../../../Entity/Dish/DishRecipe";
import PictureService from "../../../Image/PictureService";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import Authentication from "../../../Entity/Authentication/Authentication";
import DishDropElement from "../../../Entity/Dish/DragDrop/DishDropElement";

interface ClickableDishElementProps {
    dish: Dish;
    onClick: Function;
    currentDishDropElement: DishDropElement | null;
}

const ClickableDishElement = (props: ClickableDishElementProps): React.JSX.Element => {
    const [picture, setPicture] = useState<Picture|null|undefined>(undefined);
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [dishDropElement, setDishDropElement] = useState<DishDropElement>({id: (props.dish.id !== null) ? props.dish.id : 0, name: props.dish.name, pictureUrl: null})


    async function loadAndSetPictureByRecipeId(id: number): Promise<void>
    {
        if (authentication === null || (picture !== null && picture !== undefined)) {
            return;
        }

        const pictureService: PictureService = new PictureService(authentication);
        const pictures: Picture[] = await pictureService.getByRecipeId(id);

        if (pictures.length <= 0 || (picture !== undefined && picture !== null)) {
            return ;
        }

        setPicture(pictures[0]);
        setDishDropElement({
            id: dishDropElement.id,
            name: dishDropElement.name,
            pictureUrl: null
        });
    }

    async function loadPicture(): Promise<void>
    {
        const len: number = props.dish.recipes.length;

        for (let i: number = 0; i < len; i++) {
            if (picture !== undefined && picture !== null) {
                break;
            }

            await loadAndSetPictureByRecipeId(props.dish.recipes[i].id);
        }
    }

    const pictureUrlCallback = (url: string|null): void => {
        setDishDropElement(
            {
                id: dishDropElement.id,
                name: dishDropElement.name,
                pictureUrl: url
            }
        );
    }

    useEffect((): void => {
        if (picture !== undefined) {
            return;
        }

        loadPicture();

    }, [picture]);


    function onClick(): void {
        props.onClick(dishDropElement);
    }

    return (
        <div onClick={onClick} className="card-overlay-container">
            <div className="card card-shadow">
                <div className="card-header h6 nowrap-text ps-1 dragable-dish-header">
                    a {props.dish.name}
                </div>
                {picture !== undefined && picture !== null &&
                    <PictureView picture={picture}
                                 className="picture-view card-img dragable-dish-img not-clickable-picture"
                                 pictureURlCallback={pictureUrlCallback}/>
                }
                <div className="card-body dragable-dish-body ps-1">
                    {props.dish.recipes.map(
                        (recipe: DishRecipe, index: number): React.JSX.Element => {
                            return (
                                <>
                                    {index > 0 &&
                                        <>, </>
                                    }
                                    {recipe.name}
                                </>
                            );
                        }
                    )}
                </div>
            </div>
            <div className="card-overlay">&nbsp;</div>
        </div>
    );
}

export default ClickableDishElement;