import React from "react";

interface AccordionItemProps extends React.PropsWithChildren {
    id: string;
    parentId: string;
    head: string;
}

const AccordionItem = (props: AccordionItemProps): React.JSX.Element => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={"heading" + props.id}>
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapse" + props.id}
                    aria-expanded="false"
                    aria-controls={"collapse" + props.id}
                >
                    {props.head}
                </button>
            </h2>
            <div
                id={"collapse" + props.id}
                className="accordion-collapse collapse"
                aria-labelledby={"heading" + props.id}
                data-bs-parent={"#" + props.parentId }
            >
                <div className="accordion-body">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default AccordionItem;