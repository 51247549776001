import React, {useEffect, useState} from "react";
import DishDropElement from "../../../Entity/Dish/DragDrop/DishDropElement";
import IconButton from "../../../../Component/IconButton";
import CalendarEntry from "../../../Entity/Calendar/CalendarEntry";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import Authentication from "../../../Entity/Authentication/Authentication";
import CalendarService from "../../../Calendar/CalendarService";
import CalendarEntryRequest from "../../../Entity/Calendar/CalendarEntryRequest";
import {AxiosResponse} from "axios";
import PictureService from "../../../Image/PictureService";
import Picture from "../../../Entity/Picture/Picture";

interface  DishDropZoneProps {
    dateTime: Date;
    calendarEntry: CalendarEntry|null;
    label?: string;
}

const DishDropZone = (props: DishDropZoneProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [calendarService, setCalendarService] = useState<CalendarService|null>(null);
    const [pictureService, setPictureService] = useState<PictureService|null>(null);
    const [droppedItem, setDroppedItem] = useState<DishDropElement | null>(null);
    const [dragEntered, setDragEntered] = useState<boolean>(false);
    const [calendarEntry, setCalendarEntry] = useState<CalendarEntry|null>(null);

    useEffect((): void => {
        setCalendarEntry(props.calendarEntry);

        if (props.calendarEntry === null || props.calendarEntry.id === null) {
            setDroppedItem(null);
            return;
        }

        setDroppedItem({
                id: props.calendarEntry.id,
                name: props.calendarEntry.dish.name,
                pictureUrl: null
            });

    }, [props.calendarEntry]);

    useEffect((): void => {
        if (props.calendarEntry !== null && props.calendarEntry.id !== null) {
            return;
        }

        setDroppedItem(null);

    }, [props.dateTime]);

    useEffect((): void => {
        if (calendarService !== null || authentication === null) {
            return;
        }

        setCalendarService(new CalendarService(authentication));
        setPictureService(new PictureService(authentication));
    }, [authentication, calendarService, pictureService]);

    useEffect((): void => {
        if (droppedItem === null || droppedItem.pictureUrl !== null) {
            return;
        }

        loadCalendarEntryPicture();

    }, [droppedItem]);

    async function saveDroppedItem(item: DishDropElement): Promise<void>
    {
        if (calendarService === null) {
            return;
        }

        const calendarEntryRequest: CalendarEntryRequest = {
            dateTime: props.dateTime.toISOString().slice(0, 10) +
                ' ' + props.dateTime.getHours() +
                ':' + props.dateTime.getMinutes(),
            dishId: item.id
        }

        const response: AxiosResponse = await calendarService.post(calendarEntryRequest);

        console.log(item.pictureUrl);

        setDroppedItem( {
            id: parseInt(response.headers['x-id']),
            name: item.name,
            pictureUrl: item.pictureUrl
        });
    }

    async function loadCalendarEntryPicture(): Promise<void>
    {
        if (pictureService === null || droppedItem === null || props.calendarEntry === null) {
            console.log('loadCalendarEntryPicture!!!');
            console.log(droppedItem);
            console.log('---------------------------');
            return;
        }

        for (let i: number = 0; i < props.calendarEntry.dish.recipes.length; i++) {
            let pictures: Picture[] = await pictureService.getByRecipeId(props.calendarEntry.dish.recipes[i].id);

            if (pictures.length <= 0) {
                continue;
            }

            let pictureUrl: string | undefined = await pictureService.getScaledImageUrl(pictures[0],100,100);

            if (pictureUrl === undefined) {
                continue;
            }

            setDroppedItem({
                id: droppedItem.id,
                name: droppedItem.name,
                pictureUrl: pictureUrl,
            });

            break;
        }
    }

    const onDrop = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();

        const item: DishDropElement = JSON.parse(event.dataTransfer.getData('application/json'));

        setDroppedItem(item);

        saveDroppedItem(item);

        setDragEntered(false);
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
    };

    const onDragEnter = (event: React.DragEvent<HTMLDivElement>): void => {
        setDragEntered(true);
    }

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
        setDragEntered(false);
    }

    const removeDroppedItem = (): void => {

        if (calendarService === null || droppedItem === null ) {
            return;
        }

        calendarService.delete(droppedItem.id);

        setDroppedItem(null);
    }

    return (
        <div
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            className={"w-100 h-100 dish-drop-field" + ((dragEntered) ? ' dish-drop-field-over' : '')}
        >
            {droppedItem !== null &&
                <div className="card-overlay-container">
                    <div className="card card-shadow">
                        {props.label !== undefined &&
                            <div className="card-header h6 nowrap-text ps-1 dragable-dish-header">
                                {props.label}
                            </div>
                        }
                        {props.label === undefined &&
                            <div className="card-header h6 nowrap-text ps-1 dragable-dish-header">
                                {droppedItem.name}
                            </div>
                        }
                        {droppedItem.pictureUrl !== null &&
                            <img src={droppedItem.pictureUrl}
                                 className="picture-view card-img dragable-dish-img not-clickable-picture"/>
                        }
                        {props.label !== undefined &&
                            <div className="card-body">
                                {droppedItem.name}
                            </div>
                        }
                    </div>

                    <div className="card-overlay">
                        <IconButton icon="bi-trash3" iconType="Bootstrap" additionalClassName="delete-button ms-2" onClick={removeDroppedItem} />
                    </div>
                </div>
            }
            {droppedItem === null &&
                <div className="dish-drop-zone-label">
                    { props.label !== undefined &&
                        <>{props.label}</>
                    }
                    { props.label === undefined &&
                        <>{props.dateTime.getDate().toString().padStart(2, '0')}.{(props.dateTime.getMonth() + 1).toString().padStart(2, '0')}</>
                    }
                </div>
            }
        </div>
    );
}

export default DishDropZone;