import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Icon from "./Icon";

export interface TextInputProps {
    name: string,
    text: string,
    label: string,
    disabled: boolean,
    onChange: any|null,
}

export default function TextInput({name, text, label, disabled, onChange}: TextInputProps) {

    let labelId: string = 'id' + name;
    let iconId: string = 'iconId' + name;

    return (
        <>
            <Form.Group className="mb-3">
                <FloatingLabel
                    controlId={labelId}
                    label={label}
                    className="mb-3">
                    <Form.Control type="text" name={name} value={text} onChange={onChange} disabled={disabled}  />
                </FloatingLabel>
            </Form.Group>
        </>
    );
}