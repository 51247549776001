import React, {useEffect, useState} from "react";
import Picture from "../../Entity/Picture/Picture";
import FileService from "../../File/FileService";
import AuthenticationService from "../../Authentication/AuthenticationService";
import Authentication from "../../Entity/Authentication/Authentication";
import placeholder from "../../../img/Kochapp.png";

interface PictureViewProp {
    picture: Picture;
    className: string;
    width?: number;
    height?: number;
    pictureURlCallback?: Function;
}

const PictureView = (props: PictureViewProp): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [pictureUrl, setPictureUrl] = useState<string|null|undefined>(undefined);

    async function loadPicture(): Promise<void>
    {
        if (authentication === null || props.picture.file.id === null) {
            return;
        }

        const width: number = (props.width !== undefined) ? props.width : 500;
        const height: number = (props.height !== undefined) ? props.height : 500;

        const fileService: FileService = new FileService(authentication);
        const url: string|undefined = await fileService.getScaledImage(props.picture.file.id, width, height);
        if (url === undefined) {
            setPictureUrl(null);

            if (props.pictureURlCallback !== undefined) {
                props.pictureURlCallback(null);
            }

            return;
        }

        setPictureUrl(url);

        if (props.pictureURlCallback !== undefined) {
            props.pictureURlCallback(url);
        }
    }

    useEffect(() => {
        if (pictureUrl !== undefined) {
            return;
        }

        loadPicture();

    }, [pictureUrl]);

    if (pictureUrl === undefined || pictureUrl === null) {
        return (<img src={placeholder} className={"picture-view " + props.className}/>);
    }

    return (
        <>
            <img src={pictureUrl} className={"picture-view " + props.className} />
        </>
    );
}

export default PictureView;
