import BreadcrumbEntity from "../../Entity/Breadcrumb/Breadcrumb";
import React from 'react';
import {Link, useLocation} from 'react-router-dom';

interface BreadcrumbProps extends React.PropsWithChildren {
}

const Breadcrumb = (props: BreadcrumbProps): React.JSX.Element => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const breadcrumbs: BreadcrumbEntity[] = BreadcrumbEntity.createFromStringArray(pathnames,2);

    return (
        <div className="row mx-1 p-1" key="breadcrumb">
            <div className="d-flex">
            {breadcrumbs.map((breatcrumbEntity: BreadcrumbEntity): React.JSX.Element => (
                <>
                    {breatcrumbEntity.path !== location.pathname &&
                        <>
                            <Link to={breatcrumbEntity.path}>
                                {breatcrumbEntity.label}
                            </Link>
                            <div className="px-1">/</div>
                        </>
                    }

                    {breatcrumbEntity.path === location.pathname &&
                        <>
                            {breatcrumbEntity.label}
                        </>
                    }
                </>
            ))}
                {props.children}
            </div>
        </div>
    );
}

export default Breadcrumb;