import React, {useEffect, useState} from 'react';
import logo from '../../../img/Kochapp.png';
import {Link, NavigateFunction, useNavigate} from 'react-router-dom';
import AuthenticationService from '../../Authentication/AuthenticationService';
import AccountUserService from '../../AccountUser/AccountUserService';
import Authentication from '../../Entity/Authentication/Authentication';
import AccountUser from '../../Entity/AccountUser/AccountUser';

interface AuthedHeaderProps {

}

const AuthedHeader = (props: AuthedHeaderProps): React.JSX.Element => {
    const [accountUser, setAccountUser] = useState<AccountUser|null|undefined>(undefined);
    const authenticationService: AuthenticationService = new AuthenticationService();

    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const navigate: NavigateFunction = useNavigate();

    async function fetchAccountUser(): Promise<void>
    {
        if (authentication === null) {
            return;
        }

        const userService: AccountUserService = new AccountUserService(authentication);
        setAccountUser(await userService.profile());
    }

    useEffect((): void => {
            if (accountUser !== undefined) {
                return;
            }
            fetchAccountUser();
        },
        [accountUser]
    );

    const handleLogout = (): void => {
        authenticationService.deAuthenticateUser();
        navigate('/');
    }

    if (accountUser === undefined) {
        return (
            <></>
        );
    } else if(accountUser === null) {
        navigate('/');
        return (
            <></>
        );
    }

    return (
        <nav className="navbar navbar-expand-md navbar-black bg-white shadow">
            <img src={logo} className="brand-image" />
            <div className="ms-auto d-flex align-items-center text-muted">
                <div className="separator-line bg-primary"></div>
                <div className="dropdown d-flex align-items-center">
                    <button className="btn dropdown-toggle d-flex align-items-center fs-13px px-3" type="button"  data-bs-toggle="dropdown" aria-expanded="false">
                        <div  className="avatar bg-light text-secondary me-1 p-2">{accountUser.firstname?.slice(0, 1).toUpperCase()}</div>
                        <span className="d-none d-md-inline-block text-secondary text-capitalize">{accountUser.firstname}</span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end text-secondary">
                        <li>
                            <Link to="/meine-stammdaten" className="dropdown-item">
                                <i className="bi bi-person-bounding-box me-2"></i>Meine Stammdaten
                            </Link>
                        </li>
                        <li>
                            <button className="dropdown-item text-danger" onClick={handleLogout}>
                                <i className="bi bi-box-arrow-right me-2"></i>Abmelden
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="separator-line bg-primary"></div>
                <div className="dropdown text-secondary">
                    <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-list"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                            <Link to="" className="dropdown-item"><i className=""></i></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default AuthedHeader;