import React from "react";
import {Spinner} from "react-bootstrap";

const SpinnerOverlay = (): React.JSX.Element => {
    return (
        <div className="loadingSpinnerContainer">
            <Spinner />
        </div>
    );
};

export default SpinnerOverlay;
