import FieldValidationDefinition from "../FormValidationHandler/FieldValidationDefinition";
import AccountUser from "../Entity/AccountUser/AccountUser";
import RequiredValidationDefinition from "../FormValidationHandler/RequiredValidationDefinition";
import EmailValidationDefinition from "../FormValidationHandler/EmailValidationDefinition";
import PasswordValidationDefinition from "../FormValidationHandler/PasswordValidationDefinition";
import PasswordRepetitionValidationDefinition from "../FormValidationHandler/PasswordRepetitionValidationDefinition";
import FormValidationHandler from "../FormValidationHandler/FormValidationHandler";
import AuthenticationService from "../Authentication/AuthenticationService";
import FormData from "../Entity/Form/FormData";
import NotificationMessageBox from "../../Component/NotificationMessageBox";
import OverlayMessage from "../../Component/OverlayMessage";
import SuccessMessageBox from "../../Component/SuccessMessageBox";
import {Link, NavigateFunction, useNavigate} from "react-router-dom";
import SpinnerOverlay from "../../Component/SpinnerOverlay";
import {useEffect, useState} from "react";
import logo from '../../img/Kochapp.png';
import AccountUserRegisterForm from "./AccountUser/Component/AccountUserRegisterForm";

interface UserRegisterProps {

}

const UserRegister = (props: UserRegisterProps): React.JSX.Element => {
    const fieldValidationDefinitions: FieldValidationDefinition<AccountUser>[] = [
        new RequiredValidationDefinition<AccountUser>('name', 'Geben Sie bitte einen Nachnamen an.'),
        new RequiredValidationDefinition<AccountUser>('firstname', 'Geben Sie bitte einen Vornamen an.'),
        new RequiredValidationDefinition<AccountUser>('email', 'Geben Sie bitte eine gültige Email Adresse an.'),
        new EmailValidationDefinition<AccountUser>('email', 'Geben Sie bitte eine gültige Email Adresse an.'),
        new PasswordValidationDefinition<AccountUser>('password','Geben Sie bitte ein Kennwort an, welches min. 8 Zeichen lang ist, eine Zahl, ein Großbuchstaben und ein Sonderzeichen hat.'),
        new PasswordRepetitionValidationDefinition<AccountUser>('password','passwordRetype', 'Kennwörter stimmen nicht überein.'),
        new PasswordRepetitionValidationDefinition<AccountUser>('passwordRetype','password', 'Kennwörter stimmen nicht überein.'),
    ];
    const formValidationHandler: FormValidationHandler<AccountUser> = new FormValidationHandler<AccountUser>(fieldValidationDefinitions);
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSuccessMessageOverlay, setShowSuccessMessageOverlay] = useState<boolean>(false);
    const [successMessageText, setSuccessMessageText] = useState<string|null>(null);
    const [showNotificationMessageOverlay, setShowNotificationMessageOverlay] = useState<boolean>(false);
    const [notificationMessageText, setNotificationMessageText] = useState<string|null>(null);
    const navigate: NavigateFunction = useNavigate();
    const [formData, setFormData] = useState<FormData<AccountUser>>({data: new AccountUser(), formValidationHandler: formValidationHandler});


    useEffect((): void => {

        if (successMessageText === null) {
            setShowSuccessMessageOverlay(false);
            return;
        }

        setShowSuccessMessageOverlay(true);

    }, [successMessageText]);


    useEffect((): void => {

        if (successMessageText === null) {
            setShowNotificationMessageOverlay(false);
            return;
        }

        setShowNotificationMessageOverlay(true);

    }, [notificationMessageText]);

    const closeSuccessMessageOverlay = (): void => {
        setNotificationMessageText(
            'Die Überprüfung des Zugangs kann einen Moment dauern, Sie erhalten eine E-Mail sobald der Zugang aktiviert ist.'
        );
    }

    const closeNotificationMessageOverlay = (): void => {
        navigate('/');
    }

    function submitForm(): void
    {
        const form: HTMLFormElement = document.querySelector('#accountUserForm') as HTMLFormElement;
        const event: Event = new Event('submit', { bubbles: true, cancelable: true });

        if (form.reportValidity() === false) {
            return;
        }
        form.dispatchEvent(event);
    }

    const submittedCallback = (): void => {

        setSuccessMessageText(
            'Ihr Zugang wurde gespeichert und muss nun noch von einem Administrator überprüft werden!'
        );
    }

    return (
        <>
            {isLoading &&
                <SpinnerOverlay />
            }
            <div className="startPageBackground container-fluid">
                <img src={logo} className="startPageLogo col-lg-2 col-md-2 d-none d-md-block d-lg-block"  alt="neXCuisine" />
                <div className="row justify-content-center vh-100">
                    <div className="backgroundColorPrimary40 mt-5 shadow-lg col-xs-10 col-sm-10 col-md-3 col-lg-5 col-xl-6">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fs-4 mt-2 lightColor">Essensplaner und Rezeptsammlung</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col backgroundColorTransparentWhite30 mt-3 mb-3 justify-content-center align-items-center">
                                - Registrierung -
                            </div>
                        </div>
                        <div className="row">
                            <AccountUserRegisterForm setFormData={setFormData} formValidationHandler={formValidationHandler} formData={formData} submittedCallback={submittedCallback} />
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="col-sm-6 col-md-12 col-6 pt-1 text-start px-md-3">
                                        <button className="btn btn-primary w-100" onClick={submitForm}>REGISTRIEREN</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col backgroundColorTransparentWhite30 mt-3 mb-3">
                                <Link to="/" type="button">
                                    <small>Zurück zum Login?</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <OverlayMessage timeout={3000} visible={showSuccessMessageOverlay} onClose={closeSuccessMessageOverlay}>
                <SuccessMessageBox>
                    {successMessageText}
                </SuccessMessageBox>
            </OverlayMessage>
            <OverlayMessage timeout={6000} visible={showNotificationMessageOverlay} onClose={closeNotificationMessageOverlay}>
                <NotificationMessageBox>
                    {notificationMessageText}
                </NotificationMessageBox>
            </OverlayMessage>
        </>
    );
}

export default UserRegister;