import React, {useState} from "react";
import FormData from "../../../Entity/Form/FormData";
import FieldValidationDefinition from "../../../FormValidationHandler/FieldValidationDefinition";
import RequiredValidationDefinition from "../../../FormValidationHandler/RequiredValidationDefinition";
import FormValidationHandler from "../../../FormValidationHandler/FormValidationHandler";
import FontAwesomeIcon from "../../../../Component/FontAwesomeIcon";
import WorkingStep from "../../../Entity/Recipe/WorkingStep";
import TextareaField from "../../../../Component/Form/Field/TextAreaField";
import Component from "../../../Entity/Recipe/Component";

interface WorkingStepFormProps {
    workingStep: WorkingStep;
    component: Component;
    index: number;
    onRemove?: Function;
}

const WorkingStepForm = (props: WorkingStepFormProps): React.JSX.Element => {
    const workingStep: WorkingStep = props.workingStep;
    const fieldValidationDefinitions: FieldValidationDefinition<WorkingStep>[] = [
        new RequiredValidationDefinition<WorkingStep>('description', 'Geben Sie bitte eine Beschreibung an.'),
    ];
    const formValidationHandler: FormValidationHandler<WorkingStep> = new FormValidationHandler<WorkingStep>(fieldValidationDefinitions);
    const [formData, setFormData] = useState<FormData<WorkingStep>>({data: props.workingStep});

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement| HTMLTextAreaElement>): void => {
        const name: string = event.target.name;
        (workingStep as any)[name] = event.target.value;

        props.component.workingSteps[props.index] = workingStep;
        updateFormData();
        validateField(name);
    };
    const updateFormData = (): void => {
        setFormData({...formData, data: workingStep});
    };

    const validateField = (fieldName: string): void => {
        if (formValidationHandler === undefined) {
            return;
        }

        formValidationHandler.validateField(fieldName, formData);
        setFormData({...formData, errors: formData.errors});
    };

    const onRemoveClick = (): void =>
    {
        if (props.onRemove === undefined) {
            return;
        }

        props.onRemove(props.index);
    }

    return (
        <form>
            {props.index > 0 &&
            <div className="row">
                <div className="col row-seperator mt-3 mb-2"></div>
            </div>
            }
            <div className="row">
                <div className="col-10">
                    <TextareaField
                        name={"description"}
                        label="Beschreibung"
                        value={workingStep.description}
                        onChange={handleChange}
                        required={true}
                        rows={5}
                        formErrors={FormValidationHandler.getFieldErrors(formData, 'name')}
                    />
                </div>
                {props.onRemove !== undefined &&
                    <div className="col-2 d-flex justify-content-center align-items-end h4">
                        <div className="pointer-cursor">
                            <FontAwesomeIcon iconClassName="fa-trash" onClick={onRemoveClick} />
                        </div>
                    </div>
                }
            </div>
        </form>
    );
}

export default WorkingStepForm;