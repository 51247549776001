import Dish from "../Dish/Dish";
import CalendarEntryResponse from "./CalendarEntryResponse";
import CalendarEntryRequest from "./CalendarEntryRequest";

class CalendarEntry {
    private _id: number | null = null;

    private _dateTime: Date;

    private _dish: Dish;

    public static createFromCalendarEntryResponse(calenderEntryResponse: CalendarEntryResponse): CalendarEntry
    {
        const calendarEntry: CalendarEntry = new CalendarEntry();

        const [datePart, timePartWithOffset] = calenderEntryResponse.dateTime.split('T');
        const [timePart, timeOffsetPart] = timePartWithOffset.split('+');

        calendarEntry._id = calenderEntryResponse.id;
        calendarEntry._dateTime = new Date(datePart + ' ' + timePart);
        calendarEntry._dish = Dish.createFromDishResponse(calenderEntryResponse.dish);

        return calendarEntry;
    }

    public toRequest(): CalendarEntryRequest
    {
        return <CalendarEntryRequest>{
            dishId: this.dish.id,
            dateTime: this.dateTime.toDateString(),
        }
    }

    get id(): number | null {
        return this._id;
    }

    set id(value: number | null) {
        this._id = value;
    }

    get dateTime(): Date {
        return this._dateTime;
    }

    set dateTime(value: Date) {
        this._dateTime = value;
    }

    get dish(): Dish {
        return this._dish;
    }

    set dish(value: Dish) {
        this._dish = value;
    }
}

export default CalendarEntry;