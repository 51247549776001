import Footer from '../Component/Footer';
import React from 'react';
import {Outlet} from 'react-router-dom';

interface PublicBaseLayoutProps extends React.PropsWithChildren {
}

const PublicBaseLayout = (props: PublicBaseLayoutProps): React.JSX.Element => {
    return (
        <>
            <div className="body flex-grow-1 d-flex flex-row">
                <div id="contentWrapper" className="flex-column flex-grow-1">
                    <main className="position-relative flex-grow-1 d-flex flex-column">
                        <Outlet />
                    </main>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default PublicBaseLayout;
