import AuthenticationService from "../../../Authentication/AuthenticationService";
import React, {useEffect, useState} from "react";
import Dish from "../../../Entity/Dish/Dish";
import Authentication from "../../../Entity/Authentication/Authentication";
import DishService from "../../../Dish/DishService";
import DropableDishElement from "./DropableDishElement";
import DishDropElement from "../../../Entity/Dish/DragDrop/DishDropElement";
import ScrollableRow from "../../../../Component/Table/ScrollableRow";
import ClickableDishElement from "./ClicableDishElement";
import TextInput from "../../../../Component/TextInput";

interface DishContainerProps {
    mobileView: boolean;
    setDishDropElement: Function;
    currentItem: DishDropElement|null;
}

const DishesContainer = (props: DishContainerProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [dishes, setDishes] = useState<Dish[]|null|undefined>(undefined);
    const [search, setSearch] = useState<string>('');

    async function loadDishes(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const dishService: DishService = new DishService(authentication);
        setDishes(await dishService.get());
        
    }
    
    useEffect((): void => {
        if (dishes !== undefined) {
            return;
        }
        
        loadDishes();
    }, [dishes]);
    
    
    const onDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
    };

    const onDragStart = (event: DragEvent, item: DishDropElement) => {
        if (event === null || event.dataTransfer === null) {
            return;
        }

        event.dataTransfer.setData('application/json', JSON.stringify(item));
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        setSearch(event.target.value);
    }

    const dishMatchSearch = (dish: Dish, search: string): boolean => {
        let b: boolean = false;
        let lenRecipes: number = 0;
        let lenCategories: number = 0;

        if (search === '') {
            return true;
        }
        search = search.toLowerCase();

        if (dish.name.toLowerCase().indexOf(search) >= 0) {
            b = true;
        } else {
            lenRecipes = dish.recipes.length;

            for (let i: number = 0; i < lenRecipes; i++) {
                if (dish.recipes[i].name.toLowerCase().indexOf(search) >= 0) {
                    b = true;
                } else if (
                    dish.recipes[i].description !== null
                ) {
                    let description: string|null = ((dish.recipes[i].description !== null) ? dish.recipes[i].description : '');
                    if (description !== null) {
                        b = description.toLowerCase().indexOf(search) >= 0;
                    }
                }

                if (b === true) {
                    break;
                }

                lenCategories = dish.recipes[i].recipeCategories.length;

                for (let x: number = 0; x < lenCategories; x++) {
                    if (dish.recipes[i].recipeCategories[x].name.toLowerCase().indexOf(search) >= 0) {
                        b = true;
                    }

                    if (b === true) {
                        break;
                    }
                }

                if (b === true) {
                    break;
                }
            }
        }

        return b;
    }

    return (
        <div onDragOver={onDragOver}>
            {dishes !== null && dishes !== undefined &&
                <>
                    <div className="row">
                        <div className="col-12">
                            <TextInput
                                name="search"
                                text={search}
                                label="Suchen ..."
                                disabled={false}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {props.mobileView === true &&
                        <ScrollableRow>
                            {dishes.map(
                                (dish: Dish): React.JSX.Element => {
                                    if (dishMatchSearch(dish, search) === false) {
                                        return (<></>);
                                    }

                                    return (
                                        <div className="col-4">
                                            <ClickableDishElement dish={dish} currentDishDropElement={props.currentItem} onClick={props.setDishDropElement}/>
                                        </div>
                                    );
                                }
                            )}
                        </ScrollableRow>
                    }
                    {props.mobileView === false &&
                        <div className="row scrollable-col">
                            {dishes.map(
                                (dish: Dish): React.JSX.Element => {
                                    if (dishMatchSearch(dish, search) === false) {
                                        return (<></>);
                                    }

                                    return (
                                        <div className="col-4 pb-1">
                                            <DropableDishElement dish={dish} onDragStart={onDragStart}/>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default DishesContainer;