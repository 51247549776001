import FormError from '../../../Domain/Entity/Form/FormError';
import FieldStyle from './Field.module.scss';
import FieldLabel from './FieldLabel';
import React, {useEffect, useRef} from 'react';

interface TextareaProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    readonly label: string;
    readonly name: string;
    readonly description?: string;
    readonly formErrors?: FormError[];
    readonly setTextAreaRef?: Function;
}

const TextareaField = (props: TextareaProps): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;
    const textAreaRef: React.RefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement|null>(null);

    useEffect(() => {
        if (props.setTextAreaRef === undefined) {
            return;
        }
        props.setTextAreaRef(textAreaRef);

    }, [textAreaRef]);

    return (
        <>
            <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2" />
            <textarea
                name={props.name}
                value={props.value}
                rows={props.rows}
                placeholder={props.placeholder}
                required={props.required}
                className={[FieldStyle.container, hasErrors === true ? ' is-invalid' : ''].join(' ')}
                onChange={props.onChange}
                onKeyDown={props.onKeyDown}
                id={props.id}
                ref={textAreaRef}
            />
            {hasErrors === true &&
                <>
                    {props.formErrors!.map((formError: FormError, index: number): React.JSX.Element => (
                        <div key={'textarea_error_' + props.name + '_' + index} className="invalid-feedback d-block">
                            {formError.message}
                        </div>
                    ))}
                </>
            }
        </>
    );
};

export default TextareaField;
