import React, {useEffect, useState} from "react";
import SpinnerOverlay from "../../../Component/SpinnerOverlay";
import Breadcrumb from "../Component/Breadcrumb";
import Board from "../Component/Board";
import AccountUser from "../../Entity/AccountUser/AccountUser";
import AuthenticationService from "../../Authentication/AuthenticationService";
import Authentication from "../../Entity/Authentication/Authentication";
import AccountUserService from "../../AccountUser/AccountUserService";
import FloatingIcon from "../../../Component/FloatingIcon";
import {NavigateFunction, useNavigate} from "react-router-dom";
import AccountUserCard from "./Component/AccountUserCard";

interface AccountUsersOverviewProps {

}

const AccountUsersOverview = (props: AccountUsersOverviewProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accountUsers, setAccountUsers] = useState<AccountUser[]|undefined>(undefined);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [accountUser, setAccountUser] = useState<AccountUser|null|undefined>(undefined);
    const navigate: NavigateFunction = useNavigate();

    async function loadAccountUsers(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const accountUserService: AccountUserService = new AccountUserService(authentication);
        setIsLoading(true);
        setAccountUsers(await accountUserService.get());
        setIsLoading(false);
    }

    async function loadAccountUser(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const accountUserService: AccountUserService = new AccountUserService(authentication);
        const accountUserObject: AccountUser = await accountUserService.profile();

        if (accountUserService.isGranted('ROLE_ADMINISTRATOR', accountUserObject.roles) === false) {
            navigate('/dashboard');
            return;
        }
        setAccountUser(accountUserObject);
    }

    useEffect((): void  => {
        if (accountUsers !== undefined) {
            return;
        }

        loadAccountUsers();

    }, [accountUsers]);

    useEffect((): void => {
        if (accountUser !== undefined || authentication === null) {
            return;
        }
        loadAccountUser();

    }, [accountUser, authentication]);

    function openEditor(): void {
        navigate('/anwender/editor');
    }

    if (accountUser === null || accountUser === undefined) {
        return (<></>);
    }


    return (
        <>
            {isLoading === true &&
                <SpinnerOverlay />
            }
            <Breadcrumb></Breadcrumb>
            <Board>
                {((): React.JSX.Element => {
                    if (accountUsers !== undefined && accountUsers.length > 0) {
                        return (
                            <div className="row">
                                {accountUsers.map((accountUser: AccountUser): React.JSX.Element => (
                                    <div className="col-xxl-3 col-xl-4 col-12 pt-3">
                                        <AccountUserCard accountUser={accountUser} />
                                    </div>
                                ))}
                            </div>
                        );
                    } else {
                        return (<>Keine Benutzer</>);
                    }
                })()}
                <FloatingIcon onClick={openEditor} iconType="FontAwsome" icon="fa-plus" align="right" />
            </Board>
        </>
        );
}

export default AccountUsersOverview;