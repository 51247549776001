import Axios, { AxiosRequestConfig, AxiosResponse} from 'axios';

class ApiV1Provider {
    private readonly apiUrl: string;

    private readonly jsonWebToken: string | null;

    private static readonly apiVersion: string = 'v1';

    get token(): string | null {
        return this.jsonWebToken;
    }

    public constructor(apiUrl: string, jsonWebToken: string | null = null) {
        this.apiUrl = apiUrl;
        this.jsonWebToken = jsonWebToken;
    }

    public cleanUrl(url: string): string {
        url = url
                .replace(this.apiUrl, '')
                .replace('/' + ApiV1Provider.apiVersion, '');

        return url;
    }

    public get<T = any, R = AxiosResponse<T>, D = any>(path: string, config?: AxiosRequestConfig<D>): Promise<R> {
        config = this.appendJsonWebTokenAuthorizationToHeaders(config);

        return Axios.get(this.apiUrl + '/' + ApiV1Provider.apiVersion + path, config);
    };

    public getFromLocation<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
        config = this.appendJsonWebTokenAuthorizationToHeaders(config);

        return Axios.get(url, config);
    };

    public post<T = any, R = AxiosResponse<T>, D = any>(path: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        config = this.appendJsonWebTokenAuthorizationToHeaders(config);

        return Axios.post(this.apiUrl + '/' + ApiV1Provider.apiVersion + path, data, config);
    };

    public put<T = any, R = AxiosResponse<T>, D = any>(path: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        config = this.appendJsonWebTokenAuthorizationToHeaders(config);

        return Axios.put(this.apiUrl + '/' + ApiV1Provider.apiVersion + path, data, config);
    };

    public patch<T = any, R = AxiosResponse<T>, D = any>(path: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        config = this.appendJsonWebTokenAuthorizationToHeaders(config);

        return Axios.patch(this.apiUrl + '/' + ApiV1Provider.apiVersion + path, data, config);
    };

    public delete<T = any, R = AxiosResponse<T>, D = any>(path: string, config?: AxiosRequestConfig<D>): Promise<R> {
        config = this.appendJsonWebTokenAuthorizationToHeaders(config);

        return Axios.delete(this.apiUrl + '/' + ApiV1Provider.apiVersion + path, config);
    };

    private appendJsonWebTokenAuthorizationToHeaders<D = any>(config?: AxiosRequestConfig<D>): AxiosRequestConfig<D> | undefined {

        if (this.jsonWebToken === null) {
            return config;
        }

        if (config === undefined) {
            config = {};
        }

        if (config.headers === undefined) {
            config.headers = {};
        }

        config.headers.Authorization = 'Bearer ' + this.jsonWebToken;

        return config;
    }

}

export default ApiV1Provider;
