import ApiV1Provider from "../../Api/ApiV1Provider";
import Authentication from "../Entity/Authentication/Authentication";
import {AxiosResponse} from "axios";
import RecipeReaction from "../Entity/Recipe/RecipeReaction";
import RecipeReactionResponse from "../Entity/Recipe/RecipeReactionResponse";
import RecipeReactionPostRequest from "../Entity/Recipe/RecipeReactionPostRequest";
import RecipeReactionPatchRequest from "../Entity/Recipe/RecipeReactionPatchRequest";
import RecipeRequest from "../Entity/Recipe/RecipeRequest";

class RecipeReactionService {
    private readonly  apiV1Provider: ApiV1Provider

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
    }

    public async fetchByRecipeId(recipeId: number): Promise<RecipeReaction[]> {
        const recipeReactions: RecipeReaction[] = [];
        try {
            const response: AxiosResponse<RecipeReactionResponse[]> = await this.apiV1Provider.get('/recipes/reactions/' + recipeId);
            const recipeReactionResponses: RecipeReactionResponse[] = response.data;

            recipeReactionResponses.forEach((recipeReactionResponse: RecipeReactionResponse): void => {
                recipeReactions.push(RecipeReaction.createFromRecipeReactionResponse(recipeReactionResponse));
            });

            return recipeReactions;
        } catch (error) {
            throw error;
        }
    }

    public buildPatchRequest(recipeReaction: RecipeReaction): RecipeReactionPatchRequest
    {
        if (recipeReaction.id === null || recipeReaction.accountUser.id) {
            new Error();
        }

        return {
            id: (recipeReaction.id !== null) ? recipeReaction.id : 0,
            recipeId: recipeReaction.recipe.id,
            reactionType: recipeReaction.reactionType,
            accountUserId: (recipeReaction.accountUser.id !== null) ? recipeReaction.accountUser.id : 0
        }
    }

    public buildPostRequest(recipeReaction: RecipeReaction): RecipeReactionPostRequest
    {
        if (recipeReaction.id !== null || recipeReaction.accountUser.id) {
            new Error();
        }

        return {
            recipeId: recipeReaction.recipe.id,
            reactionType: recipeReaction.reactionType,
            accountUserId: (recipeReaction.accountUser.id !== null) ? recipeReaction.accountUser.id : 0
        }
    }

    public async post(recipeReaction: RecipeReaction): Promise<AxiosResponse>  {
        try {
            const recipeReactionPostRequest: RecipeReactionPostRequest = this.buildPostRequest(recipeReaction);
            const response: AxiosResponse = await this.apiV1Provider.post('/recipes/reactions', recipeReactionPostRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async put(recipeReaction: RecipeReaction): Promise<AxiosResponse>  {
        try {
            const recipeReactionPatchRequest: RecipeReactionPatchRequest = this.buildPatchRequest(recipeReaction);
            const response: AxiosResponse = await this.apiV1Provider.put('/recipes/reactions/' + recipeReactionPatchRequest.id, recipeReactionPatchRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public createRecipeReactionPostRequest(recipeReaction: RecipeReaction): RecipeReactionPostRequest
    {
        if (recipeReaction.accountUser.id === null) {
            new Error();
        }

        return {
            recipeId: recipeReaction.recipe.id,
            reactionType: recipeReaction.reactionType.valueOf(),
            accountUserId: (recipeReaction.accountUser.id !== null) ? recipeReaction.accountUser.id : 0
        }
    }

    public createRecipeReactionPatchRequest(recipeReaction: RecipeReaction): RecipeReactionPatchRequest
    {
        if (recipeReaction.accountUser.id === null || recipeReaction.id === null) {
            new Error();
        }

        return {
            id: (recipeReaction.id !== null) ? recipeReaction.id : 0,
            recipeId: recipeReaction.recipe.id,
            reactionType: recipeReaction.reactionType.valueOf(),
            accountUserId: (recipeReaction.accountUser.id !== null) ? recipeReaction.accountUser.id : 0
        }
    }
}

export default RecipeReactionService;