import DishRecipeResponse from "./DishRecipeResponse";
import AbstractRecipe from "../Recipe/AbstractRecipe";

class DishRecipe extends AbstractRecipe {
    private _position: number;

    static createFromDishRecipeResponse(dishRecipeResponse: DishRecipeResponse): DishRecipe
    {
        const dishRecipe: DishRecipe = new DishRecipe();

        super.fillFromRecipeResponse(dishRecipe, dishRecipeResponse);

        dishRecipe._position = dishRecipeResponse.position;

        return dishRecipe;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }
}

export default DishRecipe;