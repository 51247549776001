import React from "react";
import './ScrollableRow.css';

interface ScrollableRowProps  extends React.PropsWithChildren {

}

const ScrollableRow = (props: ScrollableRowProps): React.JSX.Element => {
    return (
        <div className="scrollable-container">
            <div className="row flex-nowrap">
                {props.children}
            </div>
        </div>
    );
}

export default ScrollableRow;