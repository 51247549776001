import React from "react";

interface BoardProps extends React.PropsWithChildren {
}

const Board = (props: BoardProps): React.JSX.Element => {
    return (

        <div className="board card-shadow card mx-sm-3 mx-1 p-1">
            <div className="p-1 p-md-4 justify-content-center">
                {props.children}
            </div>
        </div>
    );
}

export default Board;